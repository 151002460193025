import { camelCase } from 'lodash-es';

import { FILTERS_BY_GROUP } from '../filters';
import { JOIN_CHAR } from '../router-utils';

export const FILTERS = FILTERS_BY_GROUP.flatMap((g) => g.filters);
export const NOOP = (values) => values;

function getValueAtPath(dataset, path) {
  const fullPath = path[0] === 'parameters' ? [...path, 'value'] : path;
  return fullPath.reduce((acc, segment) => acc && acc[segment], dataset);
}

export function getAllValuesAtPath(datasets, path) {
  return datasets
    .map((d) => getValueAtPath(d, path))
    .filter((val) => val !== undefined);
}

function getUnitAtPath(dataset, path) {
  if (path[0] !== 'parameters') {
    // eslint-disable-next-line no-console
    console.warn(`Expected path to a parameter object`);
    return;
  }

  return [...path, 'unit'].reduce(
    (acc, segment) => acc && acc[segment],
    dataset
  );
}

export function getAllUnitsAtPath(datasets, path) {
  return [
    ...new Set(
      datasets
        .map((d) => getUnitAtPath(d, path))
        .filter((val) => val !== undefined)
    ),
  ];
}

export function parseRangeValue(value, step) {
  return value
    .split(JOIN_CHAR)
    .map((val) =>
      step === 1 ? Number.parseInt(val, 10) : Number.parseFloat(val)
    );
}

export function matchesFilter(dataset, filter, filterValue) {
  const value = getValueAtPath(dataset, filter.path);

  if (value === undefined) {
    return false;
  }

  if (filter.matchesValue) {
    return filter.matchesValue(value, filterValue);
  }

  switch (filter.type) {
    case 'text':
      return value.toLowerCase().includes(filterValue.toLowerCase());

    case 'radios':
      return value === filterValue;

    case 'checkboxes':
      return filterValue.split(JOIN_CHAR).includes(value);

    case 'range':
      // eslint-disable-next-line no-case-declarations
      const [min, max] = parseRangeValue(filterValue, filter.step ?? 1);
      return value >= min && value <= max;

    default:
      throw new Error(`Unknown filter type: ${filter.type}`);
  }
}

export function documentSize(datasets) {
  const sum = datasets
    .map((item) => item.size)
    .reduce((acc, val) => acc + val, 0);

  return `${sum} MB`;
}

export function getGlobusUrl(filePath) {
  const globusPath = filePath.replace(process.env.REACT_APP_GLOBUS_ROOT, '');
  const params = new URLSearchParams({
    origin_id: process.env.REACT_APP_GLOBUS_ORIGIN_ID,
    origin_path: globusPath.slice(0, globusPath.lastIndexOf('/')),
  });

  return `https://app.globus.org/file-manager?${params.toString()}`;
}

const NO_UNIT = new Set(['NA', 'None']);

export function paramToStr(param) {
  if (!param) {
    return 'unknown';
  }

  const { value, unit } = param;
  return `${value}${
    NO_UNIT.has(unit) ? '' : ` ${unit === 'years' ? 'yo' : unit}`
  }`;
}

export function processDatasetResponse(datasetResponse) {
  const parameters = (datasetResponse.parameters || []).reduce((acc, param) => {
    const { value, units } = param;

    if (/^[^_]+_/u.test(param.name)) {
      const [, group, name] = param.name.match(/^([^_]+)_(.+)$/u);
      const camelGroup = camelCase(group);

      acc[camelGroup] = acc[camelGroup] || {};
      acc[camelGroup][camelCase(name)] = { value, unit: units };
    } else {
      acc[camelCase(param.name)] = { value, unit: units };
    }

    return acc;
  }, {});

  const galleryIds = parameters.resourcesGallery?.value.split(' ') || [];
  const gallery =
    parameters.resourcesGalleryFilePaths?.value
      .split(',')
      .map((path, index) => ({
        id: galleryIds[index],
        name: path.slice(path.lastIndexOf('/') + 1),
        type: /\.(png|jpg)$/iu.test(path)
          ? 'image'
          : /\.(mp4)$/iu.test(path)
          ? 'video'
          : 'unknown',
      })) || [];

  const neuroglancerFile = gallery.find((file) =>
    file.name.includes('neuroglancer')
  );

  return {
    ...datasetResponse,
    parameters,
    gallery,
    neuroglancerFile,
    files: (datasetResponse.files || [])
      .filter(
        (f) =>
          !/^\/gallery\//u.test(f.name) &&
          !f.name.includes('neuroglancer') &&
          !/^\/pictures\//u.test(f.name)
      )
      .map((f) => ({
        ...f,
        ext: f.name.slice(f.name.lastIndexOf('.') + 1).toUpperCase(),
        shortName: f.name.slice(1, f.name.lastIndexOf('.')),
      })),
  };
}

export function trackLink(evt) {
  window._paq.push(['trackLink', evt.currentTarget.href, 'link']);
}

export function trackDownload(evt) {
  const { currentTarget: target } = evt;
  const isGlobusLink = target.href.includes('globus');

  const label = isGlobusLink ? target.href : target.getAttribute('download');
  window._paq.push(['trackLink', label, 'download']);
}

export function trackPlay(videoSrc) {
  window._paq.push(['trackEvent', 'Video', 'Play', videoSrc]);
}

export function dateFormat(date) {
  if (date.includes('T')) {
    const [shortDate] = date.split('T');
    const [year, month, day] = shortDate.split('-');
    return `${day}/${month}/${year}`;
  }
  return date;
}
