import { FiExternalLink } from 'react-icons/fi';

import { trackLink } from '../App/helpers';
import { useNeuroglancerUrl } from '../App/hooks';
import { Link, Text } from '../Primitives';

function NeuroglancerLink(props) {
  const { file } = props;
  const neuroglancerLink = useNeuroglancerUrl(file.id);

  return (
    <Link
      variant="pill"
      href={`${neuroglancerLink}`}
      data-alt2
      blank
      onClick={(evt) => trackLink(evt)}
    >
      <Text as="span" />
      <Text as="span">Visualize in Neuroglancer</Text>
      <Text as="span">
        <FiExternalLink />
      </Text>
    </Link>
  );
}
export default NeuroglancerLink;
