import { Box } from '../Primitives';

function PageWrapper(props) {
  const { children } = props;

  return (
    <Box
      mx="auto"
      mt={4}
      mb={5}
      px={[3, 3, 3, 4]}
      pt={[0, 0, 0, 3]}
      maxWidth="100rem"
      flex="1 0 auto"
      width="100%"
    >
      {children}
    </Box>
  );
}

export default PageWrapper;
