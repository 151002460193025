import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import Boundary from '../App/Boundary';
import { datasetFetcher } from '../App/SWRProvider';
import { paramToStr } from '../App/helpers';
import { useDOI, usePageTracking, useScrollToTop } from '../App/hooks';
import { Box, Heading, Flex } from '../Primitives';
import Files from './Files';
import Gallery from './Gallery';
import Metadata from './Metadata';

const FILTER_QUERY = encodeURIComponent(
  JSON.stringify({
    include: [
      { relation: 'instrument' },
      { relation: 'parameters' },
      { relation: 'files' },
    ],
  })
);

function DatasetPage(props) {
  const { isDesktop } = props;
  const { datasetId } = useParams();
  usePageTracking();
  useScrollToTop();

  const { data: dataset } = useSWR(
    `/datasets/${datasetId}?filter=${FILTER_QUERY}`,
    datasetFetcher
  );

  const { pid, files, parameters, gallery, neuroglancerFile } = dataset;
  const { title } = parameters.doi;
  const titleStr = paramToStr(title);

  const doi = useDOI(pid);

  return (
    <>
      <Helmet>
        <title>{titleStr}</title>
      </Helmet>

      <Heading as="h1" variant="display">
        {titleStr}
      </Heading>
      <Flex
        pt={2}
        flexDirection={['column', 'column', 'row']}
        gap={[3, 3, 3, 4]}
      >
        <Box flex="1 1 0%">
          <Flex column gap={[3, 3, 3, 4]}>
            <Metadata isDesktop={isDesktop} doi={doi} {...dataset} />
          </Flex>
        </Box>
        {isDesktop && (
          <Flex width={1 / 3} maxWidth="500px" column gap={[3, 3, 3, 4]}>
            <Boundary resetKeys={[pid]}>
              {files.length > 0 && (
                <Files
                  files={files}
                  doi={doi}
                  neuroglancerFile={neuroglancerFile}
                />
              )}
              {gallery.length > 0 && <Gallery files={gallery} />}
            </Boundary>
          </Flex>
        )}
      </Flex>
    </>
  );
}

export default DatasetPage;
