import { useIcatSessionId } from '../App/hooks';
import Media from './Media';

function Gallery(props) {
  const { files } = props;
  const sessionId = useIcatSessionId();

  return files.map((f) => <Media key={f.id} sessionId={sessionId} {...f} />);
}

export default Gallery;
