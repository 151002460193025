import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';

import {
  useAllDatasets,
  useIcatSessionId,
  usePageTracking,
} from '../App/hooks';
import { Flex } from '../Primitives';
import DatasetList from './DatasetList';
import OrganList from './OrganList';
import PatientList from './PatientList';

function ExplorePage() {
  const { datasets } = useAllDatasets();
  const sessionId = useIcatSessionId();
  usePageTracking();

  return (
    <>
      <Helmet>
        <title>Explore</title>
      </Helmet>

      <Flex flexDirection="column" mb={7}>
        <PatientList datasets={datasets} />

        <Route path="/explore/:patientId">
          <OrganList datasets={datasets} sessionId={sessionId} />
        </Route>

        <Route path="/explore/:patientId/:organInfo">
          <DatasetList datasets={datasets} sessionId={sessionId} />
        </Route>
      </Flex>
    </>
  );
}

export default ExplorePage;
