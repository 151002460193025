import { FiExternalLink } from 'react-icons/fi';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { datasetFetcher } from '../App/SWRProvider';
import { paramToStr, trackLink } from '../App/helpers';
import { useDOI } from '../App/hooks';
import MetaItem from '../Dataset/MetaItem';
import { Box, Card, Heading, Link, Text } from '../Primitives';

const FILTER_QUERY = encodeURIComponent(
  JSON.stringify({
    include: [{ relation: 'parameters' }, { relation: 'instrument' }],
  })
);

function DatasetDescription(props) {
  const { datasetId } = props;
  const history = useHistory();

  const { data: dataset } = useSWR(
    `/datasets/${datasetId}?filter=${FILTER_QUERY}`,
    datasetFetcher
  );

  const { pid, parameters, instrument } = dataset;
  const { abstract, title } = parameters.doi;

  const doi = useDOI(pid);

  return (
    <>
      <Card px={[3, 3, 3, 3]} py={[3, 3, 3, 3]}>
        <Heading variant="boldHeading" mb={[1, 1, 2]}>
          Description
        </Heading>
        <Text as="p" color="textVivid" mb={3}>
          {paramToStr(abstract)}
        </Text>
        <Text as="p" color="textVivid">
          The data was produced on{' '}
          <Link
            variant="inlineLink"
            href="https://mecheng.ucl.ac.uk/hip-ct/"
            blank
          >
            UCL
          </Link>
          -led beamtimes md1252/1290 at the{' '}
          <Link variant="inlineLink" href="https://www.esrf.fr/" blank>
            ESRF
          </Link>
          , funded in part by the{' '}
          <Link variant="inlineLink" href="https://chanzuckerberg.com" blank>
            CZI
          </Link>
          .
        </Text>
      </Card>
      <Box as="ul" bg="middleground" color="inherit" pl={0}>
        <MetaItem label="Dataset" narrow>
          <Link
            as={RouterLink}
            to={`/datasets/${datasetId}`}
            onClick={(evt) => {
              evt.preventDefault();
              history.push({
                pathname: `/datasets/${datasetId}`,
                state: { from: 'reconstruction' },
              });
            }}
          >
            {paramToStr(title)}
          </Link>
        </MetaItem>
        <MetaItem label="DOI" narrow>
          <Link
            variant="pill"
            href={`http://doi.org/${doi}`}
            blank
            onClick={trackLink}
          >
            <Text as="span">DOI</Text>
            <Text as="span">{doi}</Text>
            <Text as="span">
              <FiExternalLink />
            </Text>
          </Link>
        </MetaItem>
        <MetaItem label="Technique" narrow>
          {paramToStr(parameters.tomo.technique)}
        </MetaItem>
        <MetaItem label="Instrument" narrow>
          {instrument.name}, {instrument.facility}
        </MetaItem>
      </Box>
    </>
  );
}

export default DatasetDescription;
