import { JOIN_CHAR } from './router-utils';

export const FILTERS_BY_GROUP = [
  {
    groupLabel: 'Patient',
    filters: [
      {
        name: 'sex',
        label: 'Sex',
        path: ['parameters', 'samplePatient', 'sex'],
        type: 'radios',
      },
      {
        name: 'age',
        label: 'Age',
        path: ['parameters', 'samplePatient', 'age'],
        type: 'range',
        hideUnit: true,
      },
      {
        name: 'height',
        label: 'Height',
        path: ['parameters', 'samplePatient', 'size'],
        type: 'range',
      },
      {
        name: 'weight',
        label: 'Weight',
        path: ['parameters', 'samplePatient', 'weight'],
        type: 'range',
      },
    ],
  },
  {
    groupLabel: 'Sample',
    filters: [
      {
        name: 'organ',
        label: 'Organ',
        path: ['parameters', 'samplePatient', 'organName'],
        type: 'checkboxes',
        transformValue: (val) => val.replace(/^(left|right) /u, ''),
        matchesValue: (value, filterValue) =>
          filterValue.split(JOIN_CHAR).some((v) => value.endsWith(v)),
      },
      // 'samplePatient', 'organType',
      {
        name: 'pathology',
        label: 'Pathology',
        path: ['parameters', 'samplePatient', 'info'],
        type: 'text',
        placeholder: 'covid, pneumopathy, etc.',
      },
      // 'Sample_preparationDescription',
    ],
  },
  // Acquisition: ['TOMO_technique'],
  {
    groupLabel: 'Scan parameters',
    filters: [
      {
        name: 'pixel-size',
        label: 'Pixel size',
        path: ['parameters', 'tomo', 'pixelSize'],
        type: 'range',
        step: 1 / 100,
      },
      {
        name: 'energy',
        label: 'Detected avg. energy',
        path: ['parameters', 'tomo', 'energy'],
        type: 'range',
      },
      {
        name: 'surface-dose',
        label: 'Surface dose rate',
        path: ['parameters', 'tomo', 'surfaceDose'],
        type: 'range',
        step: 1 / 10,
      },
      {
        name: 'voi-dose',
        label: 'VOI integ. dose',
        path: ['parameters', 'tomo', 'totalVoiDose'],
        type: 'range',
        step: 1 / 100,
      },
    ],
  },
];
