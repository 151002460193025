import { FiUser } from 'react-icons/fi';
import { NavLink as RouterLink } from 'react-router-dom';

import { paramToStr } from '../App/helpers';
import { Box, Flex, Card, Heading, Text } from '../Primitives';

function PatientList(props) {
  const { datasets } = props;

  const patients = new Map(
    datasets.map((d) => [
      d.parameters.samplePatient.number.value,
      d.parameters.samplePatient,
    ])
  );

  return (
    <Flex flexDirection={['column', 'column', 'row']} gap={[3, 3, 3, 4]}>
      <Heading variant="display" width={['auto', 'auto', '15%']} flex="none">
        Patients
      </Heading>
      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: ['1fr', '1fr 1fr'],
        }}
      >
        {[...patients.entries()].map(([id, patient]) => (
          <Card
            key={id}
            as={RouterLink}
            to={`/explore/${id}`}
            variant="card"
            sx={{
              borderRadius: 4,
              color: 'inherit',
              textDecoration: 'none',
              '&:hover': { bg: 'foreground' },
              '&.active': {
                bg: 'foreground',
                boxShadow: 'selectedCard',
              },
              '&:hover [data-keywords] > div, &.active [data-keywords] > div': {
                bg: 'middleground',
              },
            }}
          >
            <Heading as="h3" sx={{ display: 'flex', alignItems: 'center' }}>
              <Box as={FiUser} mr={2} /> {id}
            </Heading>
            <Flex pt={1} sx={{ flexWrap: 'wrap' }} data-keywords>
              <Text variant="keyword">{paramToStr(patient.sex)}</Text>
              <Text variant="keyword">{paramToStr(patient.age)}</Text>
              {patient.weight !== undefined && (
                <Text variant="keyword">{paramToStr(patient.weight)}</Text>
              )}
              {patient.size !== undefined && (
                <Text variant="keyword">{paramToStr(patient.size)}</Text>
              )}
            </Flex>
            <Text as="p" fontSize={0}>
              {paramToStr(patient.info)}
            </Text>
          </Card>
        ))}
      </Box>
    </Flex>
  );
}

export default PatientList;
