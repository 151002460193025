/* eslint-disable jsx-a11y/control-has-associated-label */
import { getAllValuesAtPath, NOOP } from '../App/helpers';
import { useAllDatasets } from '../App/hooks';
import { Text, Flex } from '../Primitives';
import { useQueryParam } from '../router-utils';
import FilterBox from './FilterBox';

function OptionsPicker(props) {
  const { name, label, path, transformValue = NOOP } = props;
  const param = useQueryParam(name);

  const { datasets } = useAllDatasets();
  const options = new Set(
    getAllValuesAtPath(datasets, path).map(transformValue)
  );

  return (
    <FilterBox
      title={label}
      showTitle={label !== false}
      isActive={param.isActive}
      onClear={() => param.remove()}
    >
      <Flex column gap={1}>
        {[...options].map((option) => {
          const isSelected = param.value === option;

          return (
            <Flex
              key={option}
              as="label"
              sx={{
                alignItems: 'center',
                color: isSelected && 'textVivid',
                fontSize: [0, 0, 0, 0, 1],
                fontWeight: isSelected && 'bold',
                cursor: 'pointer',
                ':hover': { textDecoration: 'underline' },
              }}
            >
              <input
                name={name}
                value={option}
                type="radio"
                checked={isSelected}
                onChange={(evt) => param.setValue(option)}
                style={{ cursor: 'inherit' }}
              />

              <Text flex="1 1 0%" ml={2}>
                {option}
              </Text>
            </Flex>
          );
        })}
      </Flex>
    </FilterBox>
  );
}

export default OptionsPicker;
