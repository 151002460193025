import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import { icatAuthenticator, datasetsFetcher } from './SWRProvider';

const FILTER_QUERY = encodeURIComponent(
  JSON.stringify({ include: [{ relation: 'parameters' }] })
);

export function useAllDatasets() {
  const { data } = useSWRImmutable(
    `/datasets?filter=${FILTER_QUERY}`,
    datasetsFetcher
  );

  return {
    datasets: data,
    datasetsById: Object.fromEntries(data.map((d) => [d.pid, d])),
  };
}

export function useIcatSessionId() {
  const { data } = useSWRImmutable(
    `${process.env.REACT_APP_ICATP}/session`,
    icatAuthenticator,
    {
      revalidateOnMount: false,
      refreshInterval: 60 * 60 * 1000, // 60 min
    }
  );

  return data.sessionId;
}

export function useDOI(datasetId) {
  const sessionId = useIcatSessionId();
  const { data } = useSWRImmutable(
    `${process.env.REACT_APP_ICATP}/catalogue/${sessionId}/datacollection?datasetId=${datasetId}`
  );

  return data[0].doi;
}

export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // Skip `/reconstructions`, which redirects to first reconstruction
    if (/reconstructions\/?$/u.test(location.pathname)) {
      return;
    }

    window._paq.push(
      ['setCustomUrl', window.location.href],
      ['trackPageView', document.title]
    );
  }, [location.pathname]);
}

export function useScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
}

export function useNeuroglancerUrl(id) {
  const sessionId = useIcatSessionId();

  const url = `${process.env.REACT_APP_ICATP}/resource/${sessionId}/file/download?resourceId=${id}`;
  const { data } = useSWRImmutable(url);
  const neuroInfos = encodeURIComponent(JSON.stringify(data));
  const neuroDomain = process.env.REACT_APP_NEUROGLANCER;
  return `${neuroDomain}/#!${neuroInfos}`;
}
