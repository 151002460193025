import RCSlider from 'rc-slider';
import { useState } from 'react';
import { FiXCircle } from 'react-icons/fi';

import {
  getAllUnitsAtPath,
  getAllValuesAtPath,
  parseRangeValue,
} from '../App/helpers';
import { useAllDatasets } from '../App/hooks';
import { Button, Flex } from '../Primitives';
import { JOIN_CHAR, useQueryParam } from '../router-utils';
import FilterBox from './FilterBox';

const RangeSlider = RCSlider.createSliderWithTooltip(RCSlider.Range);

function Range(props) {
  const { name, label, path, hideUnit, step = 1 } = props;

  const { datasets } = useAllDatasets();
  const possibleValues = getAllValuesAtPath(datasets, path);
  const possibleUnits = getAllUnitsAtPath(datasets, path);

  const [min, max] = possibleValues.reduce(
    ([min, max], val) =>
      typeof val === 'number'
        ? [Math.min(val, min), Math.max(val, max)]
        : [min, max],
    [Infinity, -Infinity]
  );

  const param = useQueryParam(name);
  const initalValue = param.value
    ? parseRangeValue(param.value, step)
    : [min, max];

  const [value, setValue] = useState(initalValue);

  if (possibleUnits.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(`Range filter cannot handle multiple units: ${possibleUnits}`);
    return null;
  }

  return (
    <FilterBox
      title={`${label}${hideUnit ? '' : ` (${possibleUnits})`}`}
      showTitle={label !== false}
      isActive={param.isActive}
    >
      <Flex mt={-2} mb={-2}>
        <input
          type="checkbox"
          checked={param.isActive}
          aria-label="Toggle range filter"
          onChange={(evt) => {
            if (param.isActive) {
              param.remove();
            } else {
              param.setValue(value.join(JOIN_CHAR));
            }
          }}
          style={{ cursor: 'inherit' }}
        />
        <Flex flex="1 1 0%" alignItems="center" mr={2} ml={3}>
          <RangeSlider
            value={value}
            min={min}
            max={max}
            step={step}
            allowCross={false}
            onChange={(val) => setValue(val)}
            onAfterChange={(val) => {
              // Update param, but only if changed so as to not enable filter when tabbing through
              if (initalValue[0] !== val[0] || initalValue[1] !== val[1]) {
                param.setValue(val.join(JOIN_CHAR));
              }
            }}
          />
        </Flex>
        <Button
          variant="action"
          disabled={!param.isActive}
          aria-label="Clear"
          onClick={() => {
            setValue([min, max]);
            param.remove();
          }}
        >
          <FiXCircle />
        </Button>
      </Flex>
    </FilterBox>
  );
}

export default Range;
