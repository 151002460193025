import { Helmet } from 'react-helmet';

import { usePageTracking, useScrollToTop } from '../App/hooks';
import { Heading, Text, Box, Flex, Image, Link } from '../Primitives';
import Toc from './Toc';
import downloadAll from './download-all.png';
import downloads from './downloads.png';
import endpointResults from './endpoint-results.png';
import firefoxRetry from './firefox-retry.png';
import globusDownload from './globus-download.png';

function HelpPage() {
  usePageTracking();
  useScrollToTop();

  return (
    <>
      <Helmet>
        <title>Help</title>
      </Helmet>

      <Flex gap={[3, 3, 3, 4]} flexDirection={['column', 'column', 'row']}>
        <Box
          as="details"
          sx={{
            display: ['block', 'block', 'none'],
            position: 'relative',
            zIndex: 0,
          }}
        >
          <Box as="summary" sx={{ fontSize: 3, cursor: 'pointer' }}>
            Help
          </Box>
          <Box mt={2}>
            <Toc />
          </Box>
        </Box>
        <Box
          sx={{
            display: ['none', 'none', 'block'],
            flex: 'none',
            width: '16rem',
          }}
        >
          <Box sx={{ position: 'sticky', top: '1rem' }}>
            <Heading variant="display">Help</Heading>
            <Toc />
          </Box>
        </Box>
        <Box mt={-5}>
          <Box as="section" id="q1" mt={-2} pt={5}>
            <Heading as="h2" pt={3}>
              How to download data from the Human Organ Atlas?
            </Heading>
            <Text as="p" mb={3}>
              Once you have found the dataset you want to download, you will see
              a list of files displayed like this:
            </Text>
            <Box mb={3}>
              <Image
                variant="helpImage"
                src={downloads}
                alt="Screenshot of a list of download links as it may appear on a dataset page"
                width="350"
                height="232"
              />
            </Box>
            <Text as="p" mb={3}>
              You have two options for downloading the files:
            </Text>
            <Box as="ul">
              <Box as="li">
                <Heading as="h3" variant="smallBoldHeading">
                  Download individual files with HTTP
                </Heading>
                <Text as="p" mb={3}>
                  This is the simplest solution. Download the file you are
                  interested in by simply clicking on the name of the file. This
                  will start downloading the file using the standard web
                  protocol HTTP, which requires no extra tools to use. The
                  downside of this approach is that the file transfer protocol
                  is not reliable for big files and can fail after a few
                  gigabytes depending on your internet connection. In case of
                  failure, your browser will indicate this in the downloads box
                  or toolbar &mdash; e.g. in Firefox, if you click on the{' '}
                  <em>Downloads</em> button, you may see something like this:
                </Text>
                <Box mb={3}>
                  <Image
                    variant="helpImage"
                    src={firefoxRetry}
                    alt="Screenshot of a failed download in Firefox, with a retry button on the right-hand side"
                    width="300"
                    height="48"
                  />
                </Box>
                <Text as="p" mb={4}>
                  In this case, you can continue downloading the file by
                  clicking on the retry button (i.e. the clockwise arrow). The
                  download will resume from where it failed. You may have to
                  repeat this process multiple times for very large files (i.e.
                  tens of gigabytes). A more efficient and reliable way to
                  download large files as well as multiple files at once is to
                  use Globus (see below).
                </Text>
              </Box>

              <Box as="li">
                <Heading as="h3" variant="smallBoldHeading">
                  Download multiple files with Globus
                </Heading>
                <Text as="p" mb={3}>
                  This is also the best option for downloading large datasets
                  reliably.{' '}
                  <Link
                    variant="inlineLink"
                    href="https://www.globus.org/"
                    blank
                  >
                    Globus
                  </Link>{' '}
                  is a service for downloading and transferring files. In order
                  to use Globus to download files to your computer, you first
                  need to{' '}
                  <Link
                    variant="inlineLink"
                    href="https://www.globus.org/globus-connect-personal"
                    blank
                  >
                    install Globus Connect Personal
                  </Link>
                  . Once installed, start the software, and log in to create a{' '}
                  <Link
                    variant="inlineLink"
                    href="https://docs.globus.org/faq/globus-connect-endpoints/"
                    blank
                  >
                    local endpoint
                  </Link>
                  . In the search box, type "Human Organ Atlas" to find the
                  atlas' public endpoint:
                </Text>
                <Box mb={3}>
                  <Image
                    variant="helpImage"
                    src={endpointResults}
                    alt="Screenshot showing the Human Organ Atlas endpoint search result"
                    width="221"
                    height="93"
                  />
                </Box>
                <Text as="p" mb={3}>
                  Click on the endpoint and then click on{' '}
                  <em>Open in File Manager</em>, you should see all the datasets
                  organised by patient and organ. Now select the dataset(s) you
                  want to download. Note that you can select all the files in
                  the current folder from the file manager's top bar:
                </Text>
                <Box mb={3}>
                  <Image
                    variant="helpImage"
                    src={downloadAll}
                    alt="Screenshot showing the Globus file manager's top bar with the checkbox to select all files in the current folder"
                    width="598"
                    height="46"
                  />
                </Box>
                <Text as="p" mb={3}>
                  Select the directory in which you want the data to be
                  downloaded and start the file transfer by clicking on the{' '}
                  <em>Start</em> button. Globus will restart the transfer
                  automatically in case of failure and inform you once it is
                  complete.
                </Text>
                <Text as="p" mb={3}>
                  From a dataset's page, you can go directly to the dataset's
                  folder on Globus by clicking on the{' '}
                  <em>Download with Globus</em> button:
                </Text>
                <Box mb={3}>
                  <Image
                    src={globusDownload}
                    alt="Screenshot showing a Globus download link on a dataset page"
                    width="428"
                    height="43"
                    sx={{
                      border: '3px dashed',
                      borderColor: 'ternary',
                      borderRadius: '0.5rem',
                      p: 2,
                    }}
                  />
                </Box>
                <Text as="p" mb={3}>
                  In case of problems, please email the ESRF data managers at{' '}
                  <Link
                    variant="inlineLink"
                    href="mailto:dataportalrequests@esrf.fr"
                    blank
                  >
                    dataportalrequests@esrf.fr
                  </Link>
                  .
                </Text>
              </Box>
            </Box>
          </Box>
          <Box as="section" id="q2" pt={5}>
            <Heading as="h2" pt={3}>
              What to do if the downloaded ZIP file is corrupt?
            </Heading>
            <Text as="p">
              If you have downloaded a ZIP file and your operating system cannot
              open it because it is corrupt, then most likely the download was
              performed with HTTP. Use Globus as explained above to try to
              download the ZIP file again in a more reliable way.
            </Text>
          </Box>
          <Box as="section" id="q3" pt={5}>
            <Heading as="h2" pt={3}>
              In what format are the Human Organ Atlas data made available in?
            </Heading>
            <Text as="p">
              The processed data are made available as{' '}
              <Link
                variant="inlineLink"
                href="https://jpeg.org/jpeg2000/"
                blank
              >
                JPEG2000
              </Link>{' '}
              images. Some of the datasets of complete organs also include MP4
              videos of the reconstructed data.
            </Text>
          </Box>
          <Box as="section" id="q4" pt={5}>
            <Heading as="h2" pt={3}>
              Under what license are data from the Human Organ Atlas made
              available?
            </Heading>
            <Text as="p">
              The data are made available under the Creative Commons Attribution
              CC BY 4.0 license. This means the data are accessible by all for
              re-use as long as the publication and DOI are cited when they are
              re-used and/or new publications are published which have used the
              data.
            </Text>
          </Box>
          <Box as="section" id="q5" pt={5}>
            <Heading as="h2" pt={3}>
              How to cite the data from the Human Organ Atlas?
            </Heading>
            <Text as="p" mb={3}>
              Any publications or re-use of the data from the Human Organ Atlas
              must cite the following publication and DOI:
            </Text>
            <Text as="p">
              Walsh, C.L., Tafforeau, P., Wagner, W.L. <em>et al.</em> Imaging
              intact human organs with local resolution of cellular structures
              using hierarchical phase-contrast tomography. <em>Nat Methods</em>{' '}
              (2021).{' '}
              <Link
                variant="inlineLink"
                href="https://doi.org/10.1038/s41592-021-01317-x"
                blank
              >
                https://doi.org/10.1038/s41592-021-01317-x
              </Link>
            </Text>
          </Box>
          <Box as="section" id="q6" pt={5}>
            <Heading as="h2" pt={3}>
              How to search for data in the Human Organ Atlas?
            </Heading>
            <Text as="p">
              Click on the <em>Search</em> tab and select the filter settings
              you are interested in to find the datasets which match the
              filters.
            </Text>
          </Box>
          <Box as="section" id="q7" pt={5}>
            <Heading as="h2" pt={3}>
              Who to contact in case of problems with the Human Organ Atlas?
            </Heading>
            <Box as="ul">
              <Box as="li" mb={2}>
                For technical issues with downloading the data or using the data
                portal, please email{' '}
                <Link
                  variant="inlineLink"
                  href="mailto:dataportalrequests@esrf.fr"
                  blank
                >
                  dataportalrequests@esrf.fr
                </Link>
                .
              </Box>
              <Box as="li" mb={3}>
                For questions about the data and how they were processed, please
                contact the authors of the publication in Nature Methods (see
                above).
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
}

export default HelpPage;
