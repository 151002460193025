import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { paramToStr, dateFormat } from '../App/helpers';
import { Card, Box, Flex, Heading, Image, Link, Text } from '../Primitives';

function DatasetCard(props) {
  const { pid, parameters, gallery, sessionId, isSearchResult } = props;
  const { doi, datasetName, startDate, samplePatient } = parameters;

  const history = useHistory();
  const url = `/datasets/${encodeURIComponent(pid)}`;
  const thumbnail = gallery.find((f) => f.name.startsWith('thumbnail'));

  const { ref: thumbRef, inView } = useInView({ triggerOnce: true });

  return (
    <Flex
      as="article"
      sx={{
        minHeight: '175px',
        borderRadius: 4,
        overflow: 'hidden',
        cursor: 'pointer',
        '@media (pointer: fine)': {
          ':hover h2': { textDecoration: ['none', 'underline'] },
        },
      }}
      onClick={() => {
        history.push({
          pathname: url,
          state: { from: 'search' },
        });
      }}
    >
      <Card width={[1, 3 / 4, 3 / 4, 'auto']}>
        <Link
          as={RouterLink}
          to={url}
          onClick={(evt) => evt.preventDefault()} // let parent handler perform navigation
          noUnderline
        >
          <Heading>{paramToStr(datasetName)}</Heading>
        </Link>

        {isSearchResult && (
          <Flex pt={1} sx={{ flexWrap: 'wrap' }}>
            <Text variant="keyword">{paramToStr(samplePatient.number)}</Text>
            <Text variant="keyword">{paramToStr(samplePatient.organName)}</Text>
            <Text variant="keyword">{dateFormat(paramToStr(startDate))}</Text>
          </Flex>
        )}

        <Box as="p" mt={2} fontSize={isSearchResult ? undefined : 0}>
          {paramToStr(doi.abstract)}
        </Box>
      </Card>

      <Box
        sx={{
          display: ['none', 'block'],
          width: '175px',
          bg: 'middleground',
          flex: 'none',
        }}
      >
        {thumbnail && (
          <Image
            ref={thumbRef}
            {...(inView
              ? {
                  src: `${process.env.REACT_APP_ICATP}/resource/${sessionId}/file/download?resourceId=${thumbnail.id}`,
                }
              : {})}
          />
        )}
      </Box>
    </Flex>
  );
}

export default DatasetCard;
