/* eslint-disable jsx-a11y/control-has-associated-label */
import byteSize from 'byte-size';
import { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import Modal from 'react-responsive-modal';

import { getGlobusUrl, trackDownload, trackLink } from '../App/helpers';
import { useIcatSessionId } from '../App/hooks';
import { useCitationStore } from '../App/stores';
import { Heading, Button, Card, Box, Flex, Link, Text } from '../Primitives';
import NeuroglancerLink from './NeuroglancerLink';

function Files(props) {
  const { doi, files, neuroglancerFile } = props;
  const sessionId = useIcatSessionId();
  const globusUrl = getGlobusUrl(files[0].path);

  const { modalSeen, setModalSeen } = useCitationStore();
  const [modalState, setModalState] = useState({ open: false });

  function handleClick(evt) {
    if (modalSeen) {
      trackDownload(evt);
      return; // follow link
    }

    evt.preventDefault();
    setModalState({
      open: true,
      url: evt.currentTarget.href,
      filename: evt.currentTarget.download,
    });
  }

  return (
    <Box>
      <Flex column gap={3} fontSize={[0, 0, 0, 1, 1]}>
        {neuroglancerFile && <NeuroglancerLink file={neuroglancerFile} />}

        {files.length > 1 && (
          <Link
            variant="pill"
            href={globusUrl}
            data-alt2
            blank
            onClick={handleClick}
          >
            <Text as="span" />
            <Text as="span">Download files with Globus</Text>
            <Text as="span">
              <FiDownload />
            </Text>
          </Link>
        )}

        {[...files]
          .sort((a, b) => b.size - a.size)
          .map((f) => (
            <Link
              key={f.id}
              variant="pill"
              href={`https://ids.esrf.fr/ids/getData?sessionId=${sessionId}&datafileIds=${f.id}`}
              download={`${f.shortName}.${f.ext.toLowerCase()}`}
              data-alt
              blank
              onClick={handleClick}
            >
              <Text as="span">{f.ext}</Text>
              <Text as="span">
                <Text as="span">
                  <Text as="span">{f.shortName} </Text>
                  <Text
                    as="span"
                    sx={{
                      fontSize: '90%',
                      color: 'text',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    &mdash; {byteSize(f.size).toString()}
                  </Text>
                </Text>
              </Text>
              <Text as="span">
                <FiDownload />
              </Text>
            </Link>
          ))}
      </Flex>

      <Text as="p" fontSize={0} mt={[2, 2, 2, 3]} mb={[2, 0, 2, 0]}>
        These datasets are made freely available under the{' '}
        <Link
          variant="inlineLink"
          href="https://creativecommons.org/licenses/by-sa/4.0/"
          blank
        >
          CC‑BY‑4.0 licence
        </Link>
        . Publications using these datasets are required to{' '}
        <Link
          variant="inlineLink"
          href={`http://doi.org/${doi}`}
          blank
          onClick={trackLink}
        >
          cite the&nbsp;DOI
        </Link>
        .
      </Text>

      <Card
        as={Modal}
        center
        blockScroll={false}
        open={modalState.open}
        onClose={() => setModalState((state) => ({ ...state, open: false }))}
      >
        <Heading as="h2" variant="boldHeading" mb={3}>
          Citation required
        </Heading>
        <Text as="p" mb={3}>
          When using this data, please cite the following publication and DOI:
        </Text>
        <Text as="p" mb={4} pl={3}>
          Walsh, C.L., Tafforeau, P., Wagner, W.L. <em>et al.</em> Imaging
          intact human organs with local resolution of cellular structures using
          hierarchical phase-contrast tomography. <em>Nat Methods</em> (2021).
          https://doi.org/10.1038/s41592-021-01317-x
        </Text>
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            alignItems: 'center',
          }}
        >
          <Button
            as={Link}
            variant="primaryInverted"
            href={modalState?.url}
            download={modalState?.filename}
            blank
            onClick={(evt) => {
              trackDownload(evt);

              if (modalState.ok) {
                setModalSeen(true);
              }

              setModalState((state) => ({ ...state, open: false }));
            }}
          >
            {modalState.url?.includes('globus')
              ? 'Continue to Globus'
              : 'Download file'}
          </Button>
          <Box
            sx={{
              mt: [3, 0],
              ml: [0, 4],
              '& > :checked + &': { color: 'textVivid', fontWeight: 'bold' },
            }}
          >
            <input
              id="ok"
              type="checkbox"
              style={{ cursor: 'inherit' }}
              onChange={(evt) => {
                setModalState((state) => ({ ...state, ok: true }));
              }}
            />
            <Text
              as="label"
              htmlFor="ok"
              sx={{
                flex: '1 1 0%',
                ml: 2,
                fontSize: [0, 0, 0, 0, 1],
                cursor: 'pointer',
                ':hover': { textDecoration: 'underline' },
              }}
            >
              I understand, don't show this again
            </Text>
          </Box>
        </Flex>
      </Card>
    </Box>
  );
}

export default Files;
