import React from 'react';
import { SWRConfig } from 'swr';

import { processDatasetResponse } from './helpers';

async function fetcher(url) {
  const response = await fetch(url);

  if (!response.ok) {
    const error = new Error(response.statusText);
    error.info = await response.json();
    error.status = response.status;
    throw error;
  }

  return response.json();
}

export async function datasetFetcher(endpoint) {
  const response = await fetcher(`${process.env.REACT_APP_SEARCH}${endpoint}`);
  return processDatasetResponse(response);
}

export async function datasetsFetcher(endpoint) {
  const response = await fetcher(`${process.env.REACT_APP_SEARCH}${endpoint}`);
  return response.map(processDatasetResponse);
}

export async function icatAuthenticator(url) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      plugin: 'db',
      username: 'reader',
      password: 'reader',
    }),
  });

  return response.json();
}

function SWRProvider(props) {
  const { children } = props;

  return (
    <SWRConfig
      value={{
        suspense: true,
        revalidateOnFocus: false,
        revalidateOnMount: false,
        fetcher,
      }}
    >
      {children}
    </SWRConfig>
  );
}

export default SWRProvider;
