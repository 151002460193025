import css from '@styled-system/css';
import normalize from 'normalize.css';
import rcSlider from 'rc-slider/assets/index.css';
import rrModal from 'react-responsive-modal/styles.css';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle(
  css({
    normalize,
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
    body: {
      bg: 'background',
      color: 'text',
      fontFamily: 'text',
      fontSize: [0, 0, 1, 1, 2],
      lineHeight: 'text',
      overflow: 'visible scroll',
      accentColor: '#bb4677',
    },
    '#root': {
      height: '100vh',
    },
    ul: {
      listStyleType: 'square',
    },
    'input[type="text"]': {
      accentColor: '#646eb1',
    },
    rcSlider,
    '.rc-slider-track': { bg: 'secondary' },
    '.rc-slider-handle': { border: 'none', bg: 'ternary' },
    '.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging':
      {
        boxShadow: 'rangeHandle',
      },
    rrModal,
    '.react-responsive-modal-modal': {
      bg: 'foreground',
      color: 'textVivid',
      mt: -3,
      p: 4,
    },
    '.react-responsive-modal-closeButton': { color: 'inherit' },
    '.react-responsive-modal-closeButton > svg': { fill: 'currentColor' },
  })
);

export default GlobalStyles;
