import { useMediaQuery } from '@react-hookz/web';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import DatasetPage from '../Dataset/DatasetPage';
import ExplorePage from '../Explore/ExplorePage';
import HelpPage from '../Help/HelpPage';
import HomePage from '../Home/HomePage';
import { Flex } from '../Primitives';
import ReconstructionsPage from '../Reconstructions/ReconstructionsPage';
import SearchPage from '../Search/SearchPage';
import TutorialPage from '../Tutorial/TutorialPage';
import { breakpoints } from '../breakpoints';
import { useTheme } from '../theme';
import Boundary from './Boundary';
import GlobalStyles from './GlobalStyles';
import Navigation from './Navigation';
import PageWrapper from './PageWrapper';

function App() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints[1]})`);
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        defaultTitle="Human Organ Atlas"
        titleTemplate="%s · Human Organ Atlas"
      />

      <GlobalStyles />

      <Flex column sx={{ height: '100%' }}>
        <Navigation />

        <PageWrapper>
          <Boundary resetKeys={[location]}>
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/explore">
                <ExplorePage />
              </Route>
              <Route exact path="/search">
                <SearchPage isDesktop={isDesktop} />
              </Route>
              <Route exact path="/datasets/:datasetId">
                <DatasetPage isDesktop={isDesktop} />
              </Route>
              <Route path="/reconstructions">
                <ReconstructionsPage />
              </Route>
              <Route exact path="/help">
                <HelpPage />
              </Route>
              <Route exact path="/tutorial/:tutorialName">
                <TutorialPage />
              </Route>
            </Switch>
          </Boundary>
        </PageWrapper>
      </Flex>
    </ThemeProvider>
  );
}

export default App;
