import { useHistory } from 'react-router-dom';

import { Flex, Box, Link } from '../../Primitives';

function ToCFiji() {
  const history = useHistory();

  function handleSectionLinkClick(evt) {
    evt.preventDefault();

    const to = evt.currentTarget.getAttribute('href');
    history.push(to);

    document.querySelector(to).scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Flex as="ul" gap={2} column fontsize={1}>
      <Box as="li">
        <Link href="#preamble" onClick={handleSectionLinkClick}>
          Preamble
        </Link>
      </Box>
      <Box as="li">
        <Link href="#downloadFiji" onClick={handleSectionLinkClick}>
          Download FIJI/ImageJ
        </Link>
      </Box>
      <Box as="li">
        <Link href="#gettingStarted" onClick={handleSectionLinkClick}>
          Getting started
        </Link>
      </Box>
      <Box as="li">
        <Link href="#exampleMacro" onClick={handleSectionLinkClick}>
          Small Macro Example with BoneJ
        </Link>
      </Box>
    </Flex>
  );
}

export default ToCFiji;
