import { trackPlay } from '../App/helpers';
import { Box, Image } from '../Primitives';
import { useInView } from '.pnpm/react-intersection-observer@8.32.1_react@17.0.2/node_modules/react-intersection-observer';

function Media(props) {
  const { sessionId, id, type } = props;
  const src = `${process.env.REACT_APP_ICATP}/resource/${sessionId}/file/download?resourceId=${id}`;

  const { ref, inView } = useInView({ triggerOnce: true });

  if (type === 'image') {
    const srcAttr = inView ? { src } : {};
    return (
      <Image
        ref={ref}
        {...srcAttr}
        width="500"
        height="500"
        sc={{
          height: 'auto',
          minHeight: '500px',
          objectFit: 'contain',
          objectPosition: 'top',
        }}
      />
    );
  }

  if (type === 'video') {
    return (
      <Box
        as="video"
        ref={ref}
        controls
        muted
        loop
        width="500"
        preload="metadata"
        onPlay={() => trackPlay(src)}
        sx={{
          display: 'block',
          maxWidth: '100%',
          border: '1px solid',
          borderColor: 'middleground',
        }}
      >
        {inView && <source type="video/mp4" src={src} />}
      </Box>
    );
  }

  return null;
}

export default Media;
