import React, { useEffect } from 'react';

import { FILTERS, matchesFilter } from '../App/helpers';
import { useAllDatasets, useIcatSessionId } from '../App/hooks';
import { useAppStore } from '../App/stores';
import { Flex, Card, Text, Heading } from '../Primitives';
import { useQuery } from '../router-utils';
import DatasetCard from './DatasetCard';

function Results() {
  const { datasets, datasetsById } = useAllDatasets();
  const sessionId = useIcatSessionId();

  const query = useQuery();

  const filteredDatasets = FILTERS.reduce((acc, filter) => {
    if (!query.has(filter.name)) {
      return acc;
    }

    const filterValue = query.get(filter.name);
    return acc.filter((dataset) => matchesFilter(dataset, filter, filterValue));
  }, datasets);

  const setNumResults = useAppStore((state) => state.setNumResults);
  const isEmpty = filteredDatasets.length === 0;

  useEffect(() => {
    setNumResults(filteredDatasets.length);
    return () => setNumResults();
  }, [filteredDatasets.length, setNumResults]);

  return (
    <Flex column gap={[3, 3, 3, 4]}>
      {isEmpty ? (
        <Card p={[3, 4]}>
          <Heading>No results</Heading>
          <Text as="p">Please adjust the search filters.</Text>
        </Card>
      ) : (
        <>
          {filteredDatasets.map((dataset) => (
            <DatasetCard
              key={dataset.pid}
              sessionId={sessionId}
              isSearchResult
              {...datasetsById[dataset.pid]}
            />
          ))}
        </>
      )}
    </Flex>
  );
}
export default Results;
