import { capitalize } from 'lodash-es';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';

import Boundary from '../App/Boundary';
import { paramToStr, trackLink, dateFormat } from '../App/helpers';
import { Flex, Box, Card, Heading, Link, Text } from '../Primitives';
import Files from './Files';
import Gallery from './Gallery';
import MetaItem from './MetaItem';
import NeuroglancerWindow from './NeuroglancerWindow';

function Metadata(props) {
  const {
    doi,
    files,
    instrument,
    parameters,
    gallery,
    isDesktop,
    neuroglancerFile,
  } = props;

  const {
    doi: { abstract, users },
    startDate,
    samplePatient,
    sample,
    tomo,
    instrumentSource,
    instrumentAttenuator01,
    instrumentDetector01,
    scanType,
  } = parameters;

  return (
    <>
      <Box>
        <Card px={[3, 3, 3, 4]} py={[3, 3, 3, 4]}>
          <Heading variant="boldHeading" mb={[1, 1, 2]}>
            Description
          </Heading>
          <Text as="p" color="textVivid">
            {paramToStr(abstract)}
          </Text>
        </Card>
        <Box as="ul" bg="middleground" color="inherit" pl={0}>
          <MetaItem label="DOI">
            <Link
              variant="pill"
              href={`http://doi.org/${doi}`}
              blank
              onClick={trackLink}
            >
              <Text as="span">DOI</Text>
              <Text as="span">{doi}</Text>
              <Text as="span">
                <FiExternalLink />
              </Text>
            </Link>
          </MetaItem>
          <MetaItem label="Users">{paramToStr(users)}</MetaItem>
          <MetaItem label="Technique">{paramToStr(tomo?.technique)}</MetaItem>
          <MetaItem label="Instrument">
            {instrument.name}, {instrument.facility}
          </MetaItem>
        </Box>
      </Box>

      {isDesktop && neuroglancerFile && (
        <NeuroglancerWindow file={neuroglancerFile} />
      )}

      {!isDesktop && (
        <Flex flexDirection={['column', 'row']} gap={[3, 3, 3, 4]} my={4}>
          <Boundary resetKeys={[doi]}>
            {files.length > 0 && (
              <Files
                files={files}
                doi={doi}
                neuroglancerFile={neuroglancerFile}
              />
            )}
            {gallery.length > 0 && (
              <Flex column gap={[3, 3, 3, 4]}>
                <Gallery files={gallery} />
              </Flex>
            )}
          </Boundary>
        </Flex>
      )}

      <Box>
        <Card px={[3, 3, 3, 4]} py={[3]}>
          <Heading variant="boldHeading" mb={[0, 0]}>
            Patient
          </Heading>
        </Card>
        <Box as="ul" bg="middleground" color="inherit" pl={0}>
          <MetaItem label="Institute">
            {paramToStr(samplePatient?.institute)}
          </MetaItem>
          <MetaItem label="Number">
            {paramToStr(samplePatient?.number)}
          </MetaItem>
          <MetaItem label="Age">{paramToStr(samplePatient?.age)}</MetaItem>
          <MetaItem label="Sex">{paramToStr(samplePatient?.sex)}</MetaItem>
          <MetaItem label="Weight">
            {paramToStr(samplePatient?.weight)}
          </MetaItem>
          <MetaItem label="Height">{paramToStr(samplePatient?.size)}</MetaItem>
          <MetaItem label="Medical info">
            {paramToStr(samplePatient?.info)}
          </MetaItem>
        </Box>
      </Box>
      <Box>
        <Card px={[3, 3, 3, 4]} py={[3]}>
          <Heading variant="boldHeading" mb={[0, 0]}>
            Sample
          </Heading>
        </Card>
        <Box as="ul" bg="middleground" color="inherit" pl={0}>
          <MetaItem label="Name">{paramToStr(sample?.name)}</MetaItem>
          <MetaItem label="Organ">
            {capitalize(paramToStr(samplePatient?.organName))}
          </MetaItem>
          <MetaItem label="Info">{paramToStr(sample?.description)}</MetaItem>
          <MetaItem label="Preparation">
            {paramToStr(sample?.preparationDescription)}
          </MetaItem>
        </Box>
      </Box>
      <Box>
        <Card px={[3, 3, 3, 4]} py={[3]}>
          <Heading variant="boldHeading" mb={[0, 0]}>
            Scan parameters
          </Heading>
        </Card>
        <Box as="ul" bg="middleground" color="inherit" pl={0}>
          <MetaItem wide label="Start date">
            {dateFormat(paramToStr(startDate))}
          </MetaItem>
          <MetaItem wide label="SR current">
            {paramToStr(instrumentSource?.current)}
          </MetaItem>
          <MetaItem wide label="ID names">
            {paramToStr(tomo?.idNames)}
          </MetaItem>
          <MetaItem wide label="Scan radix">
            {paramToStr(tomo?.scanRadix)}
          </MetaItem>
          <MetaItem wide label="X step">
            {paramToStr(tomo?.xStep) || 'N.A.'}
          </MetaItem>
          <MetaItem wide label="X stages">
            {paramToStr(tomo?.xStages)}
          </MetaItem>
          <MetaItem wide label="Y step">
            {paramToStr(tomo?.yStep) || 'N.A.'}
          </MetaItem>
          <MetaItem wide label="Y stages">
            {paramToStr(tomo?.yStages)}
          </MetaItem>
          <MetaItem wide label="Z step">
            {paramToStr(tomo?.zStep)}
          </MetaItem>
          <MetaItem wide label="Z stages">
            {paramToStr(tomo?.zStages)}
          </MetaItem>
          <MetaItem wide label="Projections">
            {paramToStr(tomo?.projN)}
          </MetaItem>
          <MetaItem wide label="Ref N">
            {paramToStr(tomo?.refN) || 'N.A.'}
          </MetaItem>
          <MetaItem wide label="Dark N">
            {paramToStr(tomo?.darkN)}
          </MetaItem>
          <MetaItem wide label="Ref on">
            {paramToStr(tomo?.refOn) || 'N.A.'}
          </MetaItem>
          <MetaItem wide label="Scanning mode">
            {paramToStr(scanType)}
          </MetaItem>
          <MetaItem wide label="Exposure time">
            {paramToStr(tomo?.exposureTime)}
          </MetaItem>
          <MetaItem wide label="Acc. exposure time">
            {paramToStr(tomo?.accExposureTime)}
          </MetaItem>
          <MetaItem wide label="Acc. frames count">
            {paramToStr(tomo?.accFramesCount)}
          </MetaItem>
          <MetaItem wide label="Pixel size">
            {paramToStr(tomo?.pixelSize)}
          </MetaItem>
          <MetaItem wide label="Prop. distance">
            {paramToStr(tomo?.detectorDistance)}
          </MetaItem>
          <MetaItem wide label="Filters">
            {paramToStr(instrumentAttenuator01?.description)}
          </MetaItem>
          <MetaItem wide label="Detected avg. energy">
            {paramToStr(tomo?.energy)}
          </MetaItem>
          <MetaItem wide label="Scan geometry">
            {paramToStr(tomo?.halfAcquisition)}
          </MetaItem>
          <MetaItem wide label="Scan range">
            {paramToStr(tomo?.scanRange)}
          </MetaItem>
          <MetaItem wide label="Sensor name">
            {paramToStr(instrumentDetector01?.description)}
          </MetaItem>
          <MetaItem wide label="Sensor mode">
            {paramToStr(instrumentDetector01?.acquisitionMode)}
          </MetaItem>
          <MetaItem wide label="Sensor pixel size">
            {paramToStr(tomo?.ccdPixelSize)}
          </MetaItem>
          <MetaItem wide label="Magnification">
            {paramToStr(tomo?.magnification)}
          </MetaItem>
          <MetaItem wide label="X pixel num.">
            {paramToStr(tomo?.xPixelN)}
          </MetaItem>
          <MetaItem wide label="Y pixel num.">
            {paramToStr(tomo?.yPixelN)}
          </MetaItem>
          <MetaItem wide label="Optics type">
            {paramToStr(tomo?.opticsType)}
          </MetaItem>
          <MetaItem wide label="Scintillator">
            {paramToStr(tomo?.scintillator)}
          </MetaItem>
          <MetaItem wide label="Surface dose rate">
            {paramToStr(tomo?.surfaceDose)}
          </MetaItem>
          <MetaItem wide label="VOI dose rate">
            {paramToStr(tomo?.voiDose)}
          </MetaItem>
          <MetaItem wide label="VOI integ. dose">
            {paramToStr(tomo?.totalVoiDose)}
          </MetaItem>
          <MetaItem wide label="Scan time">
            {paramToStr(tomo?.scanTime)}
          </MetaItem>
          <MetaItem wide label="Series time">
            {paramToStr(tomo?.seriesTime)}
          </MetaItem>
        </Box>
      </Box>
      <Box>
        <Card px={[3, 3, 3, 4]} py={[3]}>
          <Heading variant="boldHeading" mb={[0, 0]}>
            Processing
          </Heading>
        </Card>
        <Box as="ul" bg="middleground" color="inherit" pl={0}>
          <MetaItem wide label="Ref. approach">
            {paramToStr(tomo?.referenceDescription)}
          </MetaItem>
          <MetaItem wide label="Volume X">
            {paramToStr(tomo?.xVolume)}
          </MetaItem>
          <MetaItem wide label="Volume Y">
            {paramToStr(tomo?.yVolume)}
          </MetaItem>
          <MetaItem wide label="Volume Z">
            {paramToStr(tomo?.zVolume)}
          </MetaItem>
          <MetaItem wide label="32 to 16 bits min.">
            {paramToStr(tomo?.min32To16Bits)}
          </MetaItem>
          <MetaItem wide label="32 to 16 bits max.">
            {paramToStr(tomo?.max32To16Bits)}
          </MetaItem>
          <MetaItem wide label="JP2 compr. ratio">
            {paramToStr(tomo?.jp2CompressRatio)}
          </MetaItem>
        </Box>
      </Box>
    </>
  );
}

export default Metadata;
