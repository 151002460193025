import React, { forwardRef } from 'react';
import { Image as RebassImage } from 'rebass/styled-components';

const Image = forwardRef(({ sx, ...props }, ref) => {
  return (
    <RebassImage
      ref={ref}
      sx={{ display: 'block', objectFit: 'cover', ...sx }}
      {...props}
    />
  );
});

export default Image;
