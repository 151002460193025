import ListPicker from './ListPicker';
import OptionsPicker from './OptionsPicker';
import Range from './Range';
import TextInput from './TextInput';

function Filter(props) {
  const { type, ...filterProps } = props;

  switch (type) {
    case 'text':
      return <TextInput {...filterProps} />;

    case 'radios':
      return <OptionsPicker {...filterProps} />;

    case 'checkboxes':
      return <ListPicker {...filterProps} />;

    case 'range':
      return <Range {...filterProps} />;

    default:
      throw new Error(`Unspported filter type: ${type}`);
  }
}

export default Filter;
