import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FiPlay, FiActivity } from 'react-icons/fi';
import {
  useRouteMatch,
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';

import Boundary from '../App/Boundary';
import { usePageTracking, useScrollToTop } from '../App/hooks';
import { Box, Flex, Heading, Text, Link } from '../Primitives';
import DatasetDescription from './DatasetDescription';

const RECONSTRUCTIONS = {
  lung: {
    datasetId: '571998122',
    embedUrl:
      'https://cloud.interspectral.com/viewerEmbedded/10/1/3667d1ac804e3bfa62e8090d3ec222dd0fca6634',
  },
  brain: {
    datasetId: '572252538',
    embedUrl:
      'https://cloud.interspectral.com/viewerEmbedded/10/0/8566fb5fcd360da3fb27cf3e557c82834cdecfb7',
  },
};

function ReconstructionsPage() {
  useScrollToTop();
  usePageTracking();

  const history = useHistory();
  const match = useRouteMatch({
    path: '/reconstructions/:kind',
    exact: true,
  });

  const kind = match?.params?.kind;

  useEffect(() => {
    if (!kind) {
      history.push('/reconstructions/lung');
    }
  }, [kind, history]);

  if (!kind) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>3D Reconstructions</title>
      </Helmet>

      <Heading as="h1" variant="display">
        Interactive 3D reconstructions
      </Heading>
      <Text as="p" fontSize={[2, 3]} mb={[4, 4, 4, 4, 5]} maxWidth="50em">
        Explore 3D visualizations of high-resolution{' '}
        <Link
          variant="inlineLink"
          href="https://mecheng.ucl.ac.uk/hip-ct/"
          blank
        >
          <Text as="abbr" title="Hierarchical Phase-Contrast Tomography">
            HiP-CT
          </Text>
        </Link>{' '}
        scans from the Human Organ Atlas &mdash; a partnership with Swedish
        company{' '}
        <Link
          variant="inlineLink"
          href="https://interspectral.com/cloud/"
          blank
        >
          Interspectral
        </Link>
        &trade;, bridging the gap between science and outreach.
      </Text>

      <Box
        sx={{
          display: ['block', 'block', 'block', 'grid'],
          gridTemplateColumns: ['none', 'none', 'none', '3fr 4fr', '1fr 2fr'],
          gridTemplateRows: 'auto 1fr',
          mb: 6,
          mr: [0, 0, 0, 0, 0, '-10rem'],
        }}
      >
        <Box mb={4} pb={[0, 0, 0, 2]}>
          <Flex
            sx={{ flexFlow: ['row wrap', 'row wrap', 'row wrap', 'column'] }}
            gap={3}
            fontSize={[0, 0, 0, 1, 1]}
          >
            <Link
              as={RouterLink}
              variant="pill"
              to="/reconstructions/lung"
              sx={{ whiteSpace: 'nowrap' }}
              {...(kind === 'lung' ? { 'data-alt3': '' } : { 'data-alt': '' })}
            >
              <Text as="span" />
              <Text as="span">Complete left lung upper lobe</Text>
              <Text as="span">
                {kind === 'lung' ? <FiActivity /> : <FiPlay />}
              </Text>
            </Link>
            <Link
              as={RouterLink}
              variant="pill"
              to="/reconstructions/brain"
              sx={{ whiteSpace: 'nowrap' }}
              {...(kind === 'brain' ? { 'data-alt3': '' } : { 'data-alt': '' })}
            >
              <Text as="span" />
              <Text as="span">Complete brain</Text>
              <Text as="span">
                {kind === 'brain' ? <FiActivity /> : <FiPlay />}
              </Text>
            </Link>
          </Flex>
        </Box>

        <Box
          sx={{
            gridRow: 'span 2',
            mb: [4, 4, 4, 0],
            pl: [0, 0, 0, 4],
          }}
        >
          <Flex
            sx={{ position: 'relative', paddingTop: 'calc(9 / 16 * 100%)' }}
          >
            <Box
              as="iframe"
              src={RECONSTRUCTIONS[kind].embedUrl}
              frameBorder={0}
              allowFullScreen
              allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Flex>
        </Box>

        <Box>
          <Boundary>
            <DatasetDescription datasetId={RECONSTRUCTIONS[kind].datasetId} />
          </Boundary>
        </Box>
      </Box>
    </>
  );
}

export default ReconstructionsPage;
