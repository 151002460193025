import { useNeuroglancerUrl } from '../App/hooks';
import { Box, Flex } from '../Primitives';

function NeuroglancerWindow(props) {
  const { file } = props;
  const neuroglancerLink = useNeuroglancerUrl(file.id);

  return (
    <Flex sx={{ position: 'relative', aspectRatio: '16/9' }}>
      <Box
        as="iframe"
        src={`${neuroglancerLink}`}
        frameBorder={0}
        allowFullScreen
        allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </Flex>
  );
}
export default NeuroglancerWindow;
