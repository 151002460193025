import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const JOIN_CHAR = '~';

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useQueryParam(name) {
  const history = useHistory();
  const query = useQuery();
  const value = query.get(name);

  const setValue = useCallback(
    (val) => {
      const params = new URLSearchParams(query);

      if (val) {
        params.set(name, val);
      } else {
        params.delete(name);
      }

      history.push({ search: params.toString() });
    },
    [history, name, query]
  );

  const remove = useCallback(() => {
    setValue('');
  }, [setValue]);

  return {
    value,
    isActive: !!value,
    setValue,
    remove,
  };
}

export function useListQueryParam(name) {
  const history = useHistory();
  const query = useQuery();

  const rawValue = query.get(name);
  const values = useMemo(
    () => (rawValue ? rawValue.split(JOIN_CHAR) : []),
    [rawValue]
  );

  const setValues = useCallback(
    (vals) => {
      const params = new URLSearchParams(query);
      if (vals.length > 0) {
        params.set(name, vals.join(JOIN_CHAR));
      } else {
        params.delete(name);
      }

      history.push({ search: params.toString() });
    },
    [history, name, query]
  );

  const toggleValue = useCallback(
    (val) => {
      const list = new Set(values);
      if (list.has(val)) {
        list.delete(val);
      } else {
        list.add(val);
      }

      setValues([...list]);
    },
    [setValues, values]
  );

  const remove = useCallback(() => {
    setValues([]);
  }, [setValues]);

  return {
    values,
    isActive: values.length > 0,
    toggleValue,
    remove,
  };
}
