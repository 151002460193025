import { Helmet } from 'react-helmet';
import { useParams, Link as RouterLink } from 'react-router-dom';

import { usePageTracking, useScrollToTop } from '../App/hooks';
import { Flex, Box, Link, Heading, Text } from '../Primitives';
import Toc from './TableOfContent';
import TutorialFiji from './TutorialFiji/TutorialFiji';
import TutorialNeuro from './TutorialNeuro';

const TUTORIALS = {
  fiji: <TutorialFiji />,
  neuro: <TutorialNeuro />,
};

function TutorialPage() {
  useScrollToTop();
  usePageTracking();

  const { tutorialName } = useParams();

  return (
    <>
      <Helmet>
        <title>Tutorials</title>
      </Helmet>

      <Flex gap={[3, 3, 3, 4]} flexDirection={['column', 'column', 'row']}>
        <Box
          as="details"
          sx={{
            display: ['block', 'block', 'none'],
            position: 'relative',
            zIndex: 0,
          }}
        >
          <Box as="summary" sx={{ fontSize: 3, cursor: 'pointer' }}>
            Tutorials
          </Box>
          <Box mt={2}>
            <Toc />
          </Box>
        </Box>
        <Box
          sx={{
            display: ['none', 'none', 'block'],
            flex: 'none',
            width: '16rem',
          }}
        >
          <Box sx={{ position: 'sticky', top: '1rem' }}>
            <Heading variant="display">Tutorials</Heading>
            <Toc />
          </Box>
        </Box>
        {tutorialName === 'list' ? (
          <Box>
            <Heading as="h2" pt={3}>
              Some tutorials to help with processing the datasets
            </Heading>
            <Box as="ul">
              <Box as="li">
                <Text as="p">
                  <Link
                    as={RouterLink}
                    variant="inlineLink"
                    to="/tutorial/fiji"
                  >
                    Fiji tutorial
                  </Link>
                  : to visualise the datasets
                </Text>
              </Box>
              <Box as="li">
                <Text as="p">
                  <Link
                    as={RouterLink}
                    variant="inlineLink"
                    to="/tutorial/neuro"
                  >
                    Neuroglancer tutorial
                  </Link>
                  : to visualise the datasets
                </Text>
              </Box>
            </Box>
          </Box>
        ) : (
          TUTORIALS[tutorialName]
        )}
      </Flex>
    </>
  );
}

export default TutorialPage;
