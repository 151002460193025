import { Helmet } from 'react-helmet';

import { Box, Heading } from '../Primitives';

function TutorialNeuro() {
  return (
    <>
      <Helmet title="Tutorial - Neuro" />

      <Box flex="1">
        <Heading as="h1" variant="display">
          Guide to Neuroglancer
        </Heading>
        <iframe
          style={{ width: '100%', height: 'auto', aspectRatio: '16/9' }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Yg0ITKw5GiE?si=PVqTHgRy4ewo4D8k"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </Box>
    </>
  );
}

export default TutorialNeuro;
