import { useHistory } from 'react-router-dom';

import { Box, Flex, Link } from '../Primitives';

function Toc() {
  const history = useHistory();

  function handleSectionLinkClick(evt) {
    evt.preventDefault();

    const to = evt.currentTarget.getAttribute('href');
    history.push(to);

    document.querySelector(to).scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <Flex gap={2} column fontSize={0}>
      <Box>
        <Link href="#q1" onClick={handleSectionLinkClick}>
          How to download data from the Human Organ Atlas?
        </Link>
      </Box>
      <Box>
        <Link href="#q2" onClick={handleSectionLinkClick}>
          What to do if the downloaded ZIP file is corrupt?
        </Link>
      </Box>
      <Box>
        <Link href="#q3" onClick={handleSectionLinkClick}>
          In what format are the Human Organ Atlas data made available in?
        </Link>
      </Box>
      <Box>
        <Link href="#q4" onClick={handleSectionLinkClick}>
          Under what license are data from the Human Organ Atlas made available?
        </Link>
      </Box>
      <Box>
        <Link href="#q5" onClick={handleSectionLinkClick}>
          How to cite the data from the Human Organ Atlas?
        </Link>
      </Box>
      <Box>
        <Link href="#q6" onClick={handleSectionLinkClick}>
          How to search for data in the Human Organ Atlas?
        </Link>
      </Box>
      <Box>
        <Link href="#q7" onClick={handleSectionLinkClick}>
          Who to contact in case of problems with the Human Organ Atlas?
        </Link>
      </Box>
    </Flex>
  );
}

export default Toc;
