import { Card, Heading, Flex, Box } from '../Primitives';
import { FILTERS_BY_GROUP } from '../filters';
import Filter from './Filter';

function SearchSidebar() {
  return (
    <Flex column gap={[3, 3, 3, 4]}>
      {FILTERS_BY_GROUP.map(({ groupLabel, filters }) => (
        <Card key={groupLabel}>
          <Flex column gap={[3, 2, 3, 4]}>
            <Box as="section">
              <Heading variant="filterGroup">{groupLabel}</Heading>
              <Flex column gap={3}>
                {filters.map((f) => (
                  <Filter key={f.name} {...f} />
                ))}
              </Flex>
            </Box>
          </Flex>
        </Card>
      ))}
    </Flex>
  );
}

export default SearchSidebar;
