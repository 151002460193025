import { capitalize } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useParams, NavLink as RouterLink } from 'react-router-dom';

import { Flex, Image, Box, Card, Heading } from '../Primitives';

function OrganList(props) {
  const { datasets, sessionId } = props;
  const { patientId } = useParams();

  const patientDatasets = datasets.filter(
    (d) => d.parameters.samplePatient.number.value === patientId
  );

  const organsInfoList = patientDatasets.map((d) => {
    const descr = d.parameters.samplePatient.organDescription?.value;
    const organ = d.parameters.samplePatient.organName.value;
    if (!descr) {
      return organ;
    }
    if (descr.startsWith('left')) {
      return `${organ} left`;
    }
    if (descr.startsWith('right')) {
      return `${organ} right`;
    }
    return organ;
  });

  const organs = new Set(organsInfoList);

  const ref = useRef(null);
  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [patientId]);

  return (
    <Flex
      ref={ref}
      flexDirection={['column', 'column', 'row']}
      gap={[3, 3, 3, 4]}
      pt={5}
    >
      <Heading
        variant="display"
        width={['auto', 'auto', '15%']}
        flex="none"
        pt={[3, 3, 4]}
      >
        Organs
      </Heading>
      <Box
        sx={{
          flex: '1 1 0%',
          display: 'grid',
          gridGap: 3,
          gridTemplateColumns: 'repeat(auto-fill, 300px)',
          alignItems: 'flex-start',
          pt: [0, 0, 4],
        }}
      >
        {[...organs].map((organ) => {
          const [organName, organPosition] = organ.split(' ');

          const organImage = patientDatasets
            .filter(
              (d) =>
                d.parameters.samplePatient.organName.value === organName &&
                (organPosition
                  ? d.parameters.samplePatient.organDescription.value.startsWith(
                      organPosition
                    )
                  : true)
            )
            .flatMap((d) => d.gallery)
            .find(
              (i) => i.name.startsWith('sample_') && i.name.endsWith('jpg')
            );

          return (
            <Card
              key={`${patientId}_${organ}`}
              as={RouterLink}
              to={`/explore/${patientId}/${organ}`}
              variant="card"
              sx={{
                borderRadius: 4,
                overflow: 'hidden',
                color: 'inherit',
                textDecoration: 'none',
                '&:hover': { bg: 'foreground' },
                '&.active': {
                  bg: 'foreground',
                  boxShadow: 'selectedCard',
                },
                '&:hover img, &.active img': {
                  bg: 'middleground',
                },
              }}
            >
              <Heading as="h4" mb={0}>
                {capitalize(
                  organPosition
                    ? `${organPosition} ${organName}`
                    : `${organName}`
                )}
              </Heading>
              {organImage && (
                <Flex
                  mx={-3}
                  mb={-3}
                  sx={{ bg: 'background', justifyContent: 'center' }}
                >
                  <Image
                    src={`${process.env.REACT_APP_ICATP}/resource/${sessionId}/file/download?resourceId=${organImage.id}`}
                    bg="foreground"
                    mt={3}
                    sx={{ maxHeight: '300px', maxWidth: '100%' }}
                  />
                </Flex>
              )}
            </Card>
          );
        })}
      </Box>
    </Flex>
  );
}

export default OrganList;
