/* eslint-disable sonarjs/no-duplicate-string */
import { useAppStore } from './App/stores';
import { breakpoints } from './breakpoints';
import { light, dark } from './colors';

export function useTheme() {
  const isDark = useAppStore((state) => state.isDark);

  return {
    colors: isDark ? dark : light,
    breakpoints,
    fonts: {
      text: 'Open Sans, system-ui, sans-serif',
      heading: 'inherit',
    },
    fontSizes: [14, 16, 16, 20, 24, 32, 48, 64, 96],
    fontWeights: {
      light: 300,
      semibold: 600,
      heading: 'normal',
    },
    lineHeights: {
      text: 1.5,
      heading: 1.25,
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
      navHeight: 60,
    },
    shadows: {
      rangeHandle: `0 0 0 3px ${isDark ? 'white' : 'black'}`,
      selectedCard: `0 0 0 2px ${isDark ? 'white' : 'black'}`,
    },
    text: {
      heading: {
        color: 'textVivid',
        fontSize: [1, 2, 2, 3, 4],
        lineHeight: 1.25,
        mb: [1, 2],
      },
      boldHeading: {
        variant: 'text.heading',
        fontWeight: ['bold', 'bold, normal'],
      },
      smallBoldHeading: {
        variant: 'text.boldHeading',
        fontSize: [0, 1, 1, 2, 3],
        mb: 3,
      },
      display: {
        fontSize: [3, 4, 4, 5, 6],
        fontWeight: ['normal', 'normal', 'normal', 'light'],
        color: ['textVivid', 'textVivid', 'textVivid', 'textVivid', 'text'],
        mt: 0,
        mb: [3, 3, 3, 4],
      },
      filterGroup: {
        fontSize: [0, 0, 0, 1],
        fontWeight: 'bold',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        mb: 2,
      },
      filter: {
        fontSize: [1, 1, 1, 2],
        mb: 2,
        '&[data-active]': { color: 'textVivid', fontWeight: 'semibold' },
      },
      keyword: {
        bg: 'foreground',
        borderRadius: 16,
        color: 'textVivid',
        fontSize: [0, 0, 0, 1],
        lineHeight: 1.75,
        whiteSpace: 'nowrap',
        mr: [1, 2],
        mb: 2,
        px: 2,
      },
      logo: {
        fontFamily: 'Carter One',
        textTransform: 'none',
        fontSize: [2, 3, 4],
      },
    },
    variants: {
      card: { p: 3, bg: 'middleground' },
      logoCard: {
        p: 3,
        bg: 'bgInverted70',
        borderRadius: 15,
        '& > img': { width: 'auto', height: [60, 70, 80, 70, 85] },
      },
      smallLogoCard: {
        variant: 'variants.logoCard',
        '& > img': { width: 'auto', height: [30, 35, 40, 35, 40] },
      },
      link: {
        color: 'primary',
        textDecoration: 'none',
        ':hover, :focus, .active': { color: 'text' },
        ':hover': { textDecoration: 'underline' },
      },
      inlineLink: {
        variant: 'variants.link',
        fontWeight: 600,
        textDecoration: 'underline',
        ':hover, :focus, .active': { color: 'ternary' },
        ':hover': { textDecoration: 'none' },
      },
      inlinePill: {
        display: 'inline-flex',
        alignItems: 'center',
        bg: 'primary',
        borderRadius: 10,
        fontSize: '85%',
        px: 1,
        textDecoration: 'none',
        color: 'textInverted',
        fontWeight: '600',
        '& > span': { mr: 1 },
        '&:hover': { bg: 'ternary', color: 'inherit' },
      },
      logoCardLink: {
        color: 'textVivid',
        outlineOffset: 3,
        '& > div': { transition: 'all 0.2s ease-out' },
        '&:hover > div, &:focus > div': { borderRadius: 25, bg: 'bgInverted' },
      },
      pill: {
        display: 'inline-flex',
        bg: 'primary',
        borderRadius: 3,
        overflow: 'hidden',
        color: 'textInverted',
        textDecoration: 'none',
        '& > span': { px: 2, display: 'flex', alignItems: 'center' },
        '& > span:first-child': {
          flex: 'none',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          bg: 'bgInverted',
          color: 'textInverted',
          fontFamily: 'monospace',
          '&:empty': { display: 'none', '& + span': {} },
        },
        '& > span:nth-child(2)': { flex: '1 1 0%', fontWeight: '600', pr: 0 },
        '& > span:last-child': { flex: 'none' },
        '&[data-alt]': { bg: 'foreground', color: 'textVivid' },
        '&[data-alt] > span:nth-child(2)': { fontWeight: 'normal', py: 2 },
        '&[data-alt] > span:first-child:empty, &[data-alt2] > span:first-child, &[data-alt3] > span:first-child':
          { display: 'none' },
        '&[data-alt2]': { bg: 'primary', color: 'textInverted' },
        '&[data-alt3]': { bg: 'ternary', color: 'textVivid' },
        '&[data-alt3]> span:nth-child(2)': { fontWeight: '700' },
        '&[data-alt] > span:first-child:empty + span, &[data-alt2] > span:nth-child(2), &[data-alt3] > span:nth-child(2)':
          { py: 2, px: 3 },
        '&:hover, &:hover span span': { bg: 'ternary', color: 'textVivid' },
      },
      helpImage: {
        border: '3px dashed',
        borderColor: 'ternary',
        borderRadius: '0.5rem',
        p: 2,
      },
    },
    buttons: {
      base: {
        display: 'inline-flex',
        alignItems: 'center',
        textAlign: 'left',
        px: 0,
        py: 0,
        bg: 'transparent',
        border: 'none',
        borderRadius: 8,
        color: 'inherit',
        fontSize: 'inherit',
        cursor: 'pointer',
        outlineOffset: 2,
        ':disabled': { pointerEvents: 'none', opacity: 0.2 },
        ':focus-visible': { outlineColor: 'currentColor' },
      },
      primary: {
        variant: 'buttons.base',
        px: 3,
        py: 2,
        bg: 'foreground',
        color: 'textVivid',
        fontSize: [1, 1, 2],
        fontWeight: 'bold',
        ':hover': { bg: 'bgInverted', color: 'textInverted' },
      },
      primaryInverted: {
        variant: 'buttons.primary',
        bg: 'white',
        color: 'textInverted',
        ':hover, :focus-visible': { bg: 'ternary', color: 'textVivid' },
      },
      action: {
        variant: 'buttons.base',
        flex: 'none',
        p: 2,
        ml: 2,
        border: '1px solid transparent',
        fontSize: [2, 2, 2, 2, 3],
        ':hover': {
          bg: 'background',
          color: 'textVivid',
          boxShadow: `0 0 2px ${isDark ? 'black' : 'white'}`,
        },
      },
    },
    forms: {
      input: {
        p: 1,
        bg: 'highlight',
        borderRadius: 4,
        borderColor: 'secondary',
      },
      switch: {
        p: '2px',
        bg: 'bgInverted',
        border: 'none',
        cursor: 'pointer',
        '&[aria-checked=true]': { bg: 'ternary' },
        ':focus': { boxShadow: 'none' },
        thumb: {
          border: 'none',
          width: 20,
          height: 20,
          mt: '0',
          ml: '0',
        },
      },
    },
  };
}
