import { Link as RouterLink } from 'react-router-dom';

import { Box, Flex, Link } from '../Primitives';

function Toc() {
  return (
    <Flex gap={2} column fontSize={1}>
      <Box>
        <Link as={RouterLink} variant="inlineLink" to="/tutorial/fiji">
          Fiji tutorial
        </Link>
      </Box>
      <Box>
        <Link as={RouterLink} variant="inlineLink" to="/tutorial/neuro">
          Neuroglancer tutorial
        </Link>
      </Box>
    </Flex>
  );
}

export default Toc;
