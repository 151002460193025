import { Helmet } from 'react-helmet';

import { Box, Link, Heading, Text, Image } from '../../Primitives';
import ToCFiji from './ToCFiji';
import contrastBeforeAfter from './contrast-before-after.png';
import iconMeasurement from './icon-measurement.png';
import importOptions from './import-options.png';
import macroInputOuput from './macro-input-output.png';
import measurementExample from './measurement-example.png';

function TutorialFiji() {
  return (
    <>
      <Helmet title="Tutorial - Fiji" />

      <Box>
        <Box
          as="details"
          sx={{
            display: ['block', 'block', 'none'],
            position: 'relative',
            zIndex: 0,
          }}
        >
          <Box as="summary" sx={{ fontSize: 3, cursor: 'pointer' }}>
            Basic Image Processing with FIJI/ImageJ
          </Box>
          <Box mt={2}>
            <ToCFiji />
          </Box>
        </Box>
        <Box
          pb={2}
          sx={{
            display: ['none', 'none', 'block'],
            position: 'sticky',
            top: '0',
            bg: 'background',
            borderBottom: '0.125rem dashed white',
          }}
        >
          <Box>
            <Heading as="h1" variant="display">
              Basic Image Processing with FIJI/ImageJ
            </Heading>
            <ToCFiji />
          </Box>
        </Box>

        <Box
          as="section"
          id="preamble"
          pt={5}
          sx={{ scrollMarginTop: '11rem' }}
        >
          <Heading as="h2" variant="heading">
            Preamble
          </Heading>
          <Text as="p" mb={3}>
            Fiji is an open-source image processing package that is built on
            ImageJ, a widely used image analysis tool. The name "Fiji" is
            actually a recursive acronym that stands for "Fiji Is Just ImageJ."
            It extends the capabilities of the original ImageJ software by
            bundling it with a set of useful plugins that facilitate scientific
            image analysis.
          </Text>
          <Text as="p" mb={3}>
            In this tutorial, we will use this{' '}
            <Link variant="inlineLink" href="/datasets/571998122" blank>
              <Text as="span" />
              <Text as="span">dataset</Text>
              <Text as="span" />
            </Link>{' '}
            to illustrate the different operation performed with Fiji.
          </Text>
          <Box as="ul" mb={3}>
            <Box as="li">
              Click on the button to download
              "211.04um_FO-20.129_lung-left_upper-lobe_pag-0.15_0.03_jp2_"
            </Box>
            <Box as="li">
              Click "I understand" on the message that remind you to cite the
              publication
            </Box>
          </Box>
          <Text as="p">
            We use the bin4 (downsampled by a factor 4) version of the volume
            because the full resolution volume's size is too large to be loaded
            by most computer.
          </Text>
        </Box>

        <Box
          as="section"
          id="downloadFiji"
          pt={5}
          sx={{ scrollMarginTop: '11rem' }}
        >
          <Heading as="h2" variant="heading">
            Download FIJI/ImageJ
          </Heading>
          <Text as="p" mb={3}>
            Go to the{' '}
            <Link variant="inlineLink" href="https://fiji.sc" blank>
              <Text as="span" />
              <Text as="span">Fiji website</Text>
              <Text as="span" />
            </Link>{' '}
            to illustrate the different operation performed with Fiji.
          </Text>
          <Text as="p">
            Select the version that corresponds to your operating system
            (Windows, macOS, and Linux) and click the download button. Install
            Fiji: Once downloaded, simply uncompress the file. Fiji doesn't
            require installation process. To launch the program, all you need to
            do is double-click the 'fiji.app' file.
          </Text>
        </Box>

        <Box
          as="section"
          id="gettingStarted"
          pt={5}
          sx={{ scrollMarginTop: '11rem' }}
        >
          <Heading as="h2" variant="heading">
            Getting started
          </Heading>

          <Box pt={3}>
            <Heading as="h3" variant="smallBoldHeading">
              Opening an image stack
            </Heading>
            <Text as="p" mb={3}>
              Common image format (.jpg, .tif, …) can be opened by dragging and
              dropping the folder or file onto the Fiji main control panel or
              with File > Import > Image Sequence For opening more complex files
              (.jp2, nd2, …) such as the image stack downloaded in the Preamble,
              drag and drop an image of the stack onto the Fiji main control
              panel or do File > Import > Bio-Formats and select an image of the
              image stack.
            </Text>
            <Image
              src={importOptions}
              alt="Screenshot showing the 'Bio-Formats Import Options' panel"
              width="580"
              height="395"
              variant="helpImage"
            />
            <Text as="p" mt={3} mb={3}>
              <strong>“Group files with similar names”</strong> has to be
              checked in order to open the complete stack
            </Text>
            <Text as="p" mb={4}>
              <strong>“Use virtual stack”</strong> can be checked if the volume
              size is too large and your computer cannot handle it, this option
              make Fiji only load the slice you are looking at. It is very
              useful for opening large datasets.
            </Text>
          </Box>

          <Box pt={3}>
            <Heading as="h3" variant="smallBoldHeading">
              Navigating in your volume
            </Heading>
            <Text as="p" mb={3}>
              <strong>Moving in z direction:</strong> Use the scrollbar at the
              bottom of the image window to navigate through the stack. Each
              position on the scrollbar represents a different slice in the
              stack. You can also use the arrow keys
            </Text>
            <Text as="p" mb={3}>
              <strong>Zooming:</strong> Use either “+” and “-” to zoom-in or
              zoom-out, or use the top and bottom arrow keys.
            </Text>
            <Text as="p" mb={4}>
              <strong>Orthogonal views:</strong> If you want to see orthogonal
              views of your dataset, click on Image > Stack > Orthogonal views.
              This is only possible if you are not in Virtual Stack mode.
            </Text>
          </Box>

          <Box pt={3}>
            <Heading as="h3" id="test" variant="smallBoldHeading">
              Adjust the contrast
            </Heading>
            <Text as="p" mb={3}>
              To adjust the contrast of the image, go in Image > Adjust >
              Brightness/Contrast and click on “Auto”.
            </Text>
            <Image
              src={contrastBeforeAfter}
              alt="Screenshot showing the difference in contrast before and after an 'auto' adjustment"
              width="515"
              height="390"
              variant="helpImage"
            />
            <Text as="p" mt={3} mb={4}>
              <strong>Apply to stack:</strong> If you want to apply these
              settings to the entire stack, click 'Apply'.
            </Text>
          </Box>

          <Box pt={3}>
            <Heading as="h3" variant="smallBoldHeading">
              Measurement
            </Heading>
            <Text as="p" mb={2}>
              If you want to measure a distance:
            </Text>
            <Box as="ul">
              <Box as="li">
                Click on the line icon{' '}
                <Image
                  src={iconMeasurement}
                  alt="Measurement icon in FIJI"
                  width="23"
                  height="24"
                  sx={{ display: 'inline' }}
                />
              </Box>
              <Box as="li">Draw the distance you want to measure</Box>
              <Box as="li">Click on Analyse > Measure</Box>
              <Box as="li" mb={2}>
                Multiply the length (given in pixel) with the voxel size
                (present in the name of the folder)
              </Box>
            </Box>
            <Image
              src={measurementExample}
              alt="Example of a measurement made on an airway of a lung"
              width="602"
              height="336"
              variant="helpImage"
            />
            <Text as="p" mt={2}>
              In this example, the diameter of the airway is 18.151 x 105.52 =
              1915 μm
            </Text>
          </Box>
        </Box>

        <Box
          as="section"
          id="exampleMacro"
          pt={5}
          pb={6}
          sx={{ scrollMarginTop: '11rem' }}
        >
          <Heading as="h2" variant="heading">
            Small Macro Example with Bonej
          </Heading>
          <Text as="p" mb={2}>
            Macro can be used in Fiji to automatize processes. Here we will show
            an example using the plugin{' '}
            <Link variant="inlineLink" href="https://bonej.org/" blank>
              <Text as="span" />
              <Text as="span">BoneJ</Text>
              <Text as="span" />
            </Link>
            .
          </Text>
          <Box as="ul">
            <Box as="li">
              Download the macro: <strong>BoneJ_analysis.ijm</strong> and the
              data: <strong>Raw_data_control</strong> from this{' '}
              <Link
                variant="inlineLink"
                href="https://www.dropbox.com/scl/fo/s1ti3hwsrmm9nht5pyjfj/h?rlkey=d4waq3dlawn0egt2zi7nv6m1b&dl=0"
              >
                <Text as="span" />
                <Text as="span">Dropbox repository</Text>
                <Text as="span" />
              </Link>
            </Box>
            <Box as="li">
              Drag and drop the macro onto the Fiji main control panel. The
              macro will appear in a new window.
            </Box>
            <Box as="li">Click on Run in the new window</Box>
            <Box as="li" pb={2}>
              Fill the input directory with the path to{' '}
              <strong>Raw_data_control</strong>. Fill the output directory where
              you want. The File suffix is .tif
            </Box>
            <Image
              src={macroInputOuput}
              alt="Panel asking for the input and output directory and the file suffix"
              width="462"
              height="213"
              variant="helpImage"
            />
            <Box as="li" pt={2}>
              After some calculation, indicate the results folder in the “Choose
              a directory” window.
            </Box>
            <Box as="li">
              After some time you should obtain color maps of the alveoli and
              struts thicknesses.
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default TutorialFiji;
