import { FiArrowLeft, FiHome } from 'react-icons/fi';
import { Route, useLocation, useHistory } from 'react-router-dom';

import { useAppStore } from '../App/stores';
import { Flex, Box, NavLink, Text } from '../Primitives';

function Navigation() {
  const numResults = useAppStore((state) => state.numResults);

  const history = useHistory();
  const { state } = useLocation();

  return (
    <Flex
      as="nav"
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        flex: 'none',
        height: 'navHeight',
        bg: 'bgNav',
        '& a': { outlineOffset: '-3px' },
      }}
    >
      <Flex flex="1 1 0%" mx="auto" maxWidth="100rem">
        <NavLink to="/" exact>
          <Box
            sx={{
              flex: 'none',
              display: ['flex', 'flex', 'none'],
              alignItems: 'center',
              fontSize: 2,
            }}
          >
            <FiHome />
          </Box>
          <Text variant="logo" sx={{ display: ['none', 'none', 'block'] }}>
            Human Organ Atlas
          </Text>
        </NavLink>

        <NavLink to="/explore">Explore</NavLink>
        <NavLink to="/search" exact>
          Search
        </NavLink>
        <NavLink to="/reconstructions" sx={{ whiteSpace: 'nowrap' }}>
          3D Reconst
          <Text as="span" display={['none', 'inline']}>
            ructions
          </Text>
          <Text as="span" display={['inline', 'none']}>
            .
          </Text>
        </NavLink>
        <NavLink to="/tutorial/list" exact>
          Tutorials
        </NavLink>
        <NavLink to="/help" exact>
          Help
        </NavLink>

        {numResults !== undefined && (
          <Route exact path="/search">
            <NavLink
              as={Box}
              to={undefined}
              ml="auto"
              sx={{ whiteSpace: 'nowrap', ':hover': { bg: 'transparent' } }}
            >
              <Text>{numResults} results</Text>
            </NavLink>
          </Route>
        )}

        <Route exact path="/datasets/:datasetId">
          <NavLink
            to="/search"
            exact
            ml="auto"
            onClick={(evt) => {
              if (state?.from) {
                evt.preventDefault();
                history.goBack();
              }
            }}
          >
            <FiArrowLeft style={{ fontSize: '1.5em', paddingTop: '1px' }} />
            <Text ml={2}>
              Back{' '}
              <Text
                as="span"
                display={['none', 'none', 'none', 'none', 'inline']}
              >
                to{' '}
                {state?.from === 'reconstruction'
                  ? 'reconstruction'
                  : 'results'}
              </Text>
            </Text>
          </NavLink>
        </Route>

        {/* <Box mx="auto" />
      <Box width="80px" mx={2} height="30px" alignSelf="center">
        <Switch
          options={[{ label: 'Light' }, { label: 'Dark' }]}
          forcedSelectedIndex={isDark ? 1 : 0}
          onChange={() => toggleTheme()}
        />
      </Box> */}
      </Flex>
    </Flex>
  );
}

export default Navigation;
