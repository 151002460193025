import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, Heading, Box } from '../Primitives';
import DatasetCard from '../Search/DatasetCard';

function DatasetList(props) {
  const { datasets, sessionId } = props;
  const { patientId, organInfo } = useParams();

  const [organ, position] = organInfo.split(' ');

  const filteredDatasets = datasets.filter(
    (d) =>
      d.parameters.samplePatient.number.value === patientId &&
      d.parameters.samplePatient.organName.value === organ &&
      (position
        ? d.parameters.samplePatient.organDescription.value.startsWith(position)
        : true)
  );

  const ref = useRef(null);
  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, [organ]);

  return (
    <Flex
      ref={ref}
      flexDirection={['column', 'column', 'row']}
      gap={[3, 3, 3, 4]}
      pt={5}
    >
      <Heading
        variant="display"
        width={['auto', 'auto', '15%']}
        flex="none"
        pt={[3, 3, 4]}
      >
        Datasets
      </Heading>
      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          alignItems: 'flex-start',
          pt: [0, 0, 4],
        }}
      >
        {filteredDatasets.map((dataset) => (
          <DatasetCard key={dataset.pid} sessionId={sessionId} {...dataset} />
        ))}
      </Box>
    </Flex>
  );
}

export default DatasetList;
