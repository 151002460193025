import React from 'react';
import { FiTwitter } from 'react-icons/fi';
import { Flex } from 'rebass';

import { trackLink } from '../App/helpers';
import {
  useIcatSessionId,
  usePageTracking,
  useScrollToTop,
} from '../App/hooks';
import { Card, Box, Link, Heading, Text, Image } from '../Primitives';
import europeanFlag from './logos/Flag_of_Europe.png';
import cziLogo from './logos/czi.png';
import cziLogo2x from './logos/czi_2x.png';
import esrfLogo from './logos/esrf.png';
import esrfLogo2x from './logos/esrf_2x.png';
import ladafLogo from './logos/ladaf.png';
import ladafLogo2x from './logos/ladaf_2x.png';
import uclLogo from './logos/ucl.png';
import uclLogo2x from './logos/ucl_2x.png';

const VIDEO_ID = '6172c99a692ec7001a3c3f52';

function HomePage() {
  const sessionId = useIcatSessionId();
  usePageTracking();
  useScrollToTop();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr', '1fr', '1fr 1fr'],
        gap: [3, 3, 3, 4],
      }}
      flexDirection={['column', 'column', 'row']}
    >
      <Box>
        <Heading as="h1" variant="display">
          Welcome to the Human&nbsp;Organ&nbsp;Atlas
        </Heading>
        <Text as="p" fontSize={[2, 3]}>
          The Human Organ Atlas uses{' '}
          <strong>Hierarchical Phase-Contrast Tomography</strong> to span a
          previously poorly explored scale in our understanding of human
          anatomy, the micron to whole intact organ scale.
        </Text>
        <p>
          Histology using optical and electron microscopy images cells and other
          structures with sub-micron accuracy but only on small biopsies of
          tissue from an organ, while clinical CT and MRI scans can image whole
          organs, but with a resolution only down to just below a millimetre.{' '}
          <abbr title="Hierarchical Phase-Contrast Tomography">HiP-CT</abbr>{' '}
          bridges these scales in 3D, imaging intact organs with ca. 20 micron
          voxels, and locally down to microns.
        </p>
        <p>
          We hope this open access Atlas, enabled by the ESRF-EBS, will act as a
          reference to provide new insights into our biological makeup in health
          and disease. To stay up to date,{' '}
          <Text as="span" mr={1}>
            follow
          </Text>{' '}
          <Link variant="inlinePill" href="https://twitter.com/hip_ct" blank>
            <Text as="span">@HiP-CT</Text>
            <FiTwitter />
          </Link>
        </p>
      </Box>

      <Box as="figure">
        <Box
          as="video"
          autoPlay
          controls
          muted
          loop
          sx={{
            display: 'block',
            width: '100%',
            border: '1px solid',
            borderColor: 'middleground',
            bg: 'middleground',
            mb: 1,
            aspectRatio: '16 / 9',
          }}
        >
          <source
            type="video/mp4"
            src={`${process.env.REACT_APP_ICATP}/resource/${sessionId}/file/download?resourceId=${VIDEO_ID}`}
          />
        </Box>
        <Text as="figcaption" sx={{ textAlign: 'center', fontStyle: 'italic' }}>
          HiP-CT imaging and 3D reconstruction of a{' '}
          <Link variant="inlineLink" href="/datasets/572252538">
            complete brain
          </Link>{' '}
          from the body donor LADAF-2020-31. More videos can be viewed on the{' '}
          <Link
            variant="inlineLink"
            href="https://www.youtube.com/channel/UC5yU8BCXUMeSi6-Ya3mR7mQ"
            blank
            onClick={trackLink}
          >
            HiP-CT YouTube channel
          </Link>
          .
        </Text>
      </Box>

      <Box>
        <Heading as="h2">Funding</Heading>
        <p>This project has been made possible by funding from:</p>
        <ul>
          <li>
            The{' '}
            <Link variant="inlineLink" href="https://www.esrf.fr/" blank>
              European Synchrotron Radiation Facility (ESRF)
            </Link>{' '}
            — funding proposal MD-1252
          </li>
          <li>
            The{' '}
            <Link variant="inlineLink" href="https://chanzuckerberg.com" blank>
              Chan Zuckerberg Initiative
            </Link>
            , a donor-advised fund of the Silicon Valley Community Foundation
          </li>
          <li>
            The{' '}
            <Link
              variant="inlineLink"
              href="https://www.ukaachen.de/kliniken-institute/institut-fuer-pathologie/register-covid-19-obduktionen/register-vorstellung/"
              blank
            >
              German Registry of COVID-19 Autopsies
            </Link>{' '}
            (DeRegCOVID), supported by the German Federal Ministry of Health
          </li>
          <li>
            The{' '}
            <Text as="span" fontWeight="600">
              Royal Academy of Engineering
            </Text>
            , UK
          </li>
          <li>
            The{' '}
            <Text as="span" fontWeight="600">
              UK Medical Research Council
            </Text>
          </li>
          <li>
            The{' '}
            <Text as="span" fontWeight="600">
              Wellcome Trust
            </Text>
          </li>
        </ul>
        <Flex sx={{ gap: [2, 3] }}>
          <Link
            variant="logoCardLink"
            href="https://mecheng.ucl.ac.uk/hip-ct/"
            blank
          >
            <Card variant="logoCard">
              <Image
                src={uclLogo}
                srcSet={`${uclLogo}, ${uclLogo2x} 2x`}
                width="292"
                height="100"
                alt=""
              />
            </Card>
          </Link>
          <Link variant="logoCardLink" href="https://www.esrf.fr/" blank>
            <Card variant="logoCard">
              <Image
                src={esrfLogo}
                srcSet={`${esrfLogo}, ${esrfLogo2x} 2x`}
                width="91"
                height="100"
                alt=""
              />
            </Card>
          </Link>
          <Link variant="logoCardLink" href="https://chanzuckerberg.com" blank>
            <Card variant="logoCard">
              <Image
                src={cziLogo}
                srcSet={`${cziLogo}, ${cziLogo2x} 2x`}
                width="197"
                height="100"
                alt=""
              />
            </Card>
          </Link>
        </Flex>
      </Box>

      <Box>
        <Heading as="h2">Collaborators</Heading>
        <ul>
          <li>
            <Link
              variant="inlineLink"
              href="https://mecheng.ucl.ac.uk/hip-ct/"
              blank
            >
              UCL
            </Link>
            , London, England:{' '}
            <strong>
              Peter D Lee, Claire Walsh, Simon Walker-Samuel, Rebecca Shipley,
              Sebastian Marussi, Joseph Jacob, David Long, Daniyal Jafree, Ryo
              Torii, Charlotte Hagen
            </strong>
          </li>
          <li>
            <Link variant="inlineLink" href="http://www.esrf.eu/" blank>
              ESRF
            </Link>
            , Grenoble, France: <strong>Paul Tafforeau, Elodie Boller</strong>
          </li>
          <li>
            Medizinische Hochschule Hannover, Germany:{' '}
            <strong>Danny D Jonigk, Christopher Werlein, Mark Kuehnel</strong>
          </li>
          <li>
            Universitätsmedizin der Johannes Gutenberg-Universität Mainz,
            Germany:
            <strong>M Ackermann</strong>
          </li>
          <li>
            University Hospital of Heidelberg, Germany:{' '}
            <strong>Willi Wagner</strong>
          </li>
          <li>
            Grenoble Alpes University, Department of Anatomy, French National
            Center for Scientific Research: <strong>A Bellier</strong>
          </li>
          <li>
            <Link
              variant="inlineLink"
              href="https://www.diamond.ac.uk/Home.html;jsessionid=330A1E9C238D428D3F1188ABB0488B19"
              blank
            >
              Diamond Light Source
            </Link>
            , Harwell, UK: <strong>Andy Bodey, Robert C Atwood</strong>
          </li>
          <li>
            Imperial College London, UK: <strong>JL Robertus</strong>
          </li>
        </ul>
        <Flex sx={{ gap: [2, 3] }}>
          <Link variant="logoCardLink" href="https://www.ladaf.fr" blank>
            <Card variant="smallLogoCard">
              <Image
                src={ladafLogo}
                srcSet={`${ladafLogo}, ${ladafLogo2x} 2x`}
                width="217"
                height="40"
                alt=""
              />
            </Card>
          </Link>
        </Flex>
      </Box>

      <Box>
        <Heading as="h2">Reference</Heading>
        <Text as="p">
          Walsh, C.L., Tafforeau, P., Wagner, W.L. <em>et al.</em> Imaging
          intact human organs with local resolution of cellular structures using
          hierarchical phase-contrast tomography. <em>Nat Methods</em> (2021).{' '}
          <Link
            variant="inlineLink"
            href="https://doi.org/10.1038/s41592-021-01317-x"
            blank
          >
            https://doi.org/10.1038/s41592-021-01317-x
          </Link>
        </Text>
      </Box>

      <Box>
        <Heading as="h2">Acknowledgements</Heading>
        <Text as="p">
          The development of this portal has been done as part of the{' '}
          <Link variant="inlineLink" href="https://www.panosc.eu/" blank>
            PaNOSC project
          </Link>
          .
        </Text>
        <Flex sx={{ gap: [2], alignItems: 'center' }}>
          <Image
            src={europeanFlag}
            alt=""
            sx={{
              flex: '0 0 60px',
              border: '1px solid grey',
            }}
          />
          <Text>
            PaNOSC has received funding from the European Union’s{' '}
            <Link
              variant="inlineLink"
              href="https://ec.europa.eu/programmes/horizon2020/en/what-horizon-2020"
              blank
            >
              Horizon 2020
            </Link>{' '}
            research and innovation programme under grant agreement No. 823852.
          </Text>
        </Flex>
        <Text as="p">
          The following people were involved in the development: Paul Tafforeau,
          Alejandro De Maria Antolinos, Axel Bocciarelli, Marjolaine Bodin and
          Andrew Götz from the ESRF, Jiří Majer from ELI, as well as the broader
          PaNOSC and ICAT communities.
        </Text>
      </Box>
    </Box>
  );
}

export default HomePage;
